import  { USER_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import { Notification } from "element-react";

 class UserActions {
  constructor(_props) {
    this.api = config['USER']
    this.rest  = 'u'
    this.arcive = '/b/'
  }

    paginationUser = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const searchString = qs.stringify(query)
              const response = await axios.get(
                this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                })

                response.data.data = response.data.data.map(item => ({ ...item, userGroup: item.group.name }))

                dispatch({
                    type: USER_TYPES.USER_PAG_F,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: USER_TYPES.USER_PAG_R,
                    payload: e
                })
            }
        }
    }


  seeAll = (jwt, page, count, query, search) => {
    return async dispatch => {

      try {

        dispatch({
          type: USER_TYPES.FETCH_USER_SEE_ALL_P
        })

        const searchString = qs.stringify(query)

        const { data } = await axios.get(
          this.api + this.rest + `/r/` + page + '/' + count + '?' + searchString,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })


          dispatch({
            type: USER_TYPES.FETCH_USER_SEE_ALL_F,
            payload: data,
            search: search
          })

      } catch (e) {

        dispatch({
          type: USER_TYPES.FETCH_USER_SEE_ALL_R,
          payload: e
        })
        
      }
    }
  }

    update = (jwt, body, Type, history) => {
      return async dispatch => {
        try {
            await axios.put(
            this.api + this.rest + `/${body.userId}`,
            body,
            {
              headers: 
                {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })

            Notification.warning({
              title: 'User',
              message: 'User has been succesfuly updated!'
            }, 20)

            dispatch({
                type: USER_TYPES[Type + '_UPDATE_F'],
                payload: body
            })

            history.push('/system/user')
            
        } catch (e) {

            if (e.response.status === 422) {
              if (Array.isArray(e.response.data)) {
                  e.response.data.forEach(err => {
                      Notification.error({
                          title: err.path,
                          message: err.msg
                      }, 20)
                  })
              } else {
                  Notification.error({
                      title: e.response.data.path,
                      message: e.response.data.msg
                  }, 20)
              }
            }

            dispatch({
                type: USER_TYPES[Type + '_UPDATE_R'],
                payload: e
            })

          }
        }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: USER_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: USER_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: USER_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: USER_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    showWorkedHours = (jwt, id, query) => {
      return async dispatch => {
        try {

          dispatch({
              type: USER_TYPES.FETCH_USER_WORKED_HOURS_P
          })

          const searchString = qs.stringify(query)
          
          const response = await axios.get(
            this.api + this.rest + `/worked_hours/${id}?${searchString}`,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )

            dispatch({
                type: USER_TYPES.FETCH_USER_WORKED_HOURS_F,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: USER_TYPES.FETCH_USER_WORKED_HOURS_R,
                payload: e
            })
          }
        }
    }

    listWorkedHours = (jwt, id, query) => {
      return async dispatch => {
        try {

          dispatch({
              type: USER_TYPES.FETCH_USER_WORKED_HOURS_LIST_P
          })
          
          const response = await axios.get(
            this.api + this.rest + `/worked_hours/${id}/${query.page}/${query.count}`,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )

            dispatch({
                type: USER_TYPES.FETCH_USER_WORKED_HOURS_LIST_F,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: USER_TYPES.FETCH_USER_WORKED_HOURS_LIST_R,
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: USER_TYPES.USER_BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.paginationUser(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: USER_TYPES.USER_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }


    /**
     * @name bulkDestroy
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + this.rest + this.arcive + 'a',
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: USER_TYPES.USER_BULK_DESTROY_F,
                payload: ids
            })

            dispatch(this.paginationUser(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: USER_TYPES.USER_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

      create = (jwt, body, Type, history) => {
        return async dispatch => {
            await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                Notification.success({
                  title: 'User',
                  message: 'User has been succesfuly create!'
                }, 20)

                dispatch({
                    type: USER_TYPES[Type + '_CREATE_F'],
                    payload: response
                })

                history.push('/system/user')

              })
              .catch(e => {

                if (e.response.status === 422) {
                  if (Array.isArray(e.response.data)) {
                      e.response.data.forEach(err => {
                          Notification.error({
                              title: err.path,
                              message: err.msg
                          }, 20)
                      })
                  } else {
                      Notification.error({
                          title: e.response.data.path,
                          message: e.response.data.msg
                      }, 20)
                  }
                }
                
                dispatch({
                      type: USER_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })

              })
        }
      }
}




export default new UserActions()