import { TIME_TRACKING_TYPES } from '../../types'
import TimeTrackingFields from '../../../system/UserTimeTracking/type'

const initialState = {
  data: [],
  navItem: new TimeTrackingFields().navBar,
  oneData: new TimeTrackingFields(),
  error: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedTimeTrackingPage') || 1,
    notRemoved: localStorage.getItem('notRemovedTimeTrackingPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedTimeTrackingPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedTimeTrackingPageSize') || 10
  },
  langTab : {
    TimeTrackingId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  dataFilter: {
    name: '',
    nameF: false,
    code: false,
    loacle: false,
    TimeTrackingId: false,
    sortOrder: false
  },
  tabPanel: '1',
  checked: false,
  ids: []
};



const timeTracking = ( state = initialState, action = {}) => {

  switch (action.type) {

    case TIME_TRACKING_TYPES.NEW_TIME_TRACKING: {
      return { 
        ...state,
        oneData: new TimeTrackingFields(),
        error: []
      }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_PAG_F: {

        return {
          ...state,
          data: action.payload.data.map((elem) => state.ids.includes(elem.userAttendanceReportId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.userAttendanceReportId)),
          count: action.payload.count
        }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.userAttendanceReportId) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case TIME_TRACKING_TYPES.TIME_TRACKING_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new TimeTrackingFields().navBar,
          error: []
      }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_REQUIRED_VALIDATION_R: {
      state.navItem = new TimeTrackingFields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new TimeTrackingFields().navBar
      }
    }

    case TIME_TRACKING_TYPES.NEW_USER: {
      return { 
          ...state,
          oneData: new TimeTrackingFields(),
          navItem: new TimeTrackingFields().navBar,
          error: []
      }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case TIME_TRACKING_TYPES.TIME_TRACKING_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.userAttendanceReportId !== id)
      }
    }
    
    default:
            return state;
  }
}
export {
  timeTracking
}