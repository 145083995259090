import {PRODUCT_TYPES} from "../../types/index";
import { updateDimensions } from '../../services/mobileService'
import config from '../../../../config/app.json'
const isMobile = updateDimensions()


  // calculate product price
  const calculateProductPrice = (product) => {
    const calculateSum = (arr, field) => arr.reduce((prev, curr) => prev + curr[field], 0);

    let price = Number.parseFloat(product.price).toFixed(1);

    const requiredOptions = [];
    const options = [];

    function sortArray(dataArr) {
      return dataArr.sort((a, b) => {
        return (parseInt(b.sortOrder) != null ? parseInt(b.sortOrder) : Infinity) - (parseInt(a.sortOrder) != null ? parseInt(a.sortOrder) : Infinity);
      });
    }

    if (product.options) {
      product.options.forEach(({values, required, option}, index) => {
        // remove not exist stock options
        const optionValues = values.filter(({subtract}) => subtract);
        delete product.options[index].value;
        if ((required || option.type == 'select') && optionValues.length) {
          const value = sortArray(optionValues)[0];
          value.price = Math.round((value.price) * Math.pow(10, 0)) / Math.pow(10, 0);
          if (!value.pricePrefix) {
            price = value.price;
          } else {
            options.push({...value, type: option.type});
          }
          requiredOptions.push({...value, type: option.type});
        }
      });
    }

    if (options.length) {
      class OptionCollection extends Array {
        sum(key) {
          return this.reduce((a, b) => a + b['pricePrefix'] + (b[key] || 0), 0);
        }
      }

      const option = new OptionCollection(...options);
      price = price + calculateSum(option, 'price');
    }

    return {
      price,
      options: requiredOptions,
    };
  };

const defaultState = {
  pagRemovedProduct: [],
  pagNotRemovedProduct: [],
  pagRemovedProductCount: 0,
  pagNotRemovedProductCount: 0,
  productSelect: [],
  initialFilter: {
    productIds: [],
    name: '',
    model: '',
    status: null,
    minQuantity: null,
    maxQuantity: null,
    minPrice: null,
    maxPrice: null,
    deleted: null,
    stors: [],
    franchises: [],
    productGroupIds: [],
    brandIds: [],
    categoryIds: []
  },
  pageNumber: {
    removed: 1,
    notRemoved: 1
  },
  showEntries: {
    removed: 10,
    notRemoved: 10
  },
  langTab : {
    userGroupId: 79,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'd/',
    removed: 'notRemoved'
  },
  dataFilter: {
    productId: null,
    nameF: '',
    modelF: '',
    priceF: null,
    quantityF: null,
    statusF: null,
    sortOrder: null,
    dateAdded: null,
    filter: false
  },
  tabPanel: localStorage.getItem('productActiveTab') || '1',
  checked: false,
  ids: [],
  data: [],
  count: 0,
  loading: {
    list: false,
    create: false
  },
  isOpen: isMobile ? false : localStorage.getItem('productFilter') == 'true' ? true : false,
  exportProducts: [],
  categories: {
    data: [],
    count: 0
  },
  error: {},
  brands: {
    data: [],
    count: 0
  },
  minMaxValues: {},
  importLoad: true
};

const allProduct = (state = [], action = {}) => {
  if (action.type === PRODUCT_TYPES.GET_ALL_PRODUCTS) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedProduct = (state = [], action = {}) => {
  if (action.type === PRODUCT_TYPES.GET_ALL_REMOVED_PRODUCTS) {
    return action.payload;
  } else {
    return state
  }
};

const product = (state = {}, action = {}) => {
  if (action.type === PRODUCT_TYPES.GET_PRODUCT_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedProduct = (state = {}, action = {}) => {
  if (action.type === PRODUCT_TYPES.SELECT_PRODUCT_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const products = ( state = defaultState, action = {}) => {
  switch (action.type) {

      case PRODUCT_TYPES.PRODUCTS_PAG_F: {

          return {
            ...state,
            data: action.payload.produts,
            count: action.payload.count
          }
      }

      case PRODUCT_TYPES.GET_PAG_REMOVED_PRODUCTS_P: {
        return {
          ...state,
          loading: { ...state.loading, ...{ list: true } }
        }
      }

      case PRODUCT_TYPES.GET_PAG_REMOVED_PRODUCTS_F: {
          return {
            ...state,
            pagRemovedProduct: action.payload.data,
            pagRemovedProductCount: action.payload.count,
            loading: { ...state.loading, ...{ list: false } }
          }
      }

      case PRODUCT_TYPES.GET_PAG_REMOVED_PRODUCTS_R: {
        return {
          ...state,
          error: action.payload.data,
          loading: { ...state.loading, ...{ list: false } }
        }
      }

      case PRODUCT_TYPES.GET_PAG_NOT_REMOVED_PRODUCTS_P: {
        return {
          ...state,
          loading: { ...state.loading, ...{ list: true } }
        }
      }

      case PRODUCT_TYPES.GET_PAG_NOT_REMOVED_PRODUCTS_F: {
          return {
            ...state,
            pagNotRemovedProduct: action.payload.data,
            pagNotRemovedProductCount: action.payload.count,
            loading: { ...state.loading, ...{ list: false } }
          }
      }

      case PRODUCT_TYPES.GET_PAG_NOT_REMOVED_PRODUCTS_R: {
        return {
          ...state,
          error: action.payload.data,
          loading: { ...state.loading, ...{ list: false } }
        }
      }

      case PRODUCT_TYPES.DELETE_PRODUCT_ITEMS: {
        const id = action.payload.productId;
        return {
            ...state,
            pagNotRemovedProduct: state.pagNotRemovedProduct.filter(item => item.productId !== id)
        }
      }

      case PRODUCT_TYPES.PRODUCTS_DATA_FILTER: {
        return { 
            ...state,
            dataFilter:  { ...{ [action.payload['key']]: action.payload['value'] }  }
        }
      }

      case PRODUCT_TYPES.RECOVER_PRODUCT_ITEMS: {
        const id = action.payload.productId;
        state.pagNotRemovedProduct.unshift(action.payload)
        return {
            ...state,
            pagRemovedProduct: state.pagRemovedProduct.filter(item => item.productId !== id),
            pagNotRemovedProduct: state.pagNotRemovedProduct,
            pagNotRemovedProductCount: state.pagNotRemovedProductCount + 1
        }
      }

      case PRODUCT_TYPES.ARCHIVE_PRODUCT_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            // pagNotRemovedProductCount: state.pagNotRemovedProductCount - action.payload.length
        }
      }

    case PRODUCT_TYPES.BULK_RECOVER_PRODUCT_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload
        }
      }

      case PRODUCT_TYPES.SAVE_PRODUCT_P: {
        
        return {
            ...state,
            loading: { ...state.loading, ...{ create: true } }
        }
      }


      case PRODUCT_TYPES.SAVE_PRODUCT_F: {

          return {
              ...state,
              loading: { ...state.loading, ...{ create: false } }
          }
      }

      case PRODUCT_TYPES.SAVE_PRODUCT_R: {

          return {
              ...state,
              loading: { ...state.loading, ...{ create: false } }
          }
      }

      case PRODUCT_TYPES.UPDATE_PRODUCT_P: {

        return {
            ...state,
            loading: { ...state.loading, ...{ create: true } }
        }

      }
  
      case PRODUCT_TYPES.UPDATE_PRODUCT_F: {

          return {
              ...state,
              loading: { ...state.loading, ...{ create: false } }
          }

      }

      case PRODUCT_TYPES.UPDATE_PRODUCT_R: {

        return {
            ...state,
            loading: { ...state.loading, ...{ create: false } }
        }

      }

      case PRODUCT_TYPES.SELECT_PRODUCT_ITEMS: {

        return {
            ...state
        } 
      }

      case PRODUCT_TYPES.DESTROY_PRODUCT: {
        return {
            ...state,
            pagRemovedProduct: state.pagRemovedProduct.filter(item => item.productId !== action.payload.productId),
            loading: false
        }
      }
    case 'DATA_FILTER_F': {

        return {
            ...state,
            pagNotRemovedProduct: action.payload.data,
            pagNotRemovedProductCount: action.payload.count,
            pagRemovedProduct: action.payload.data,
            pagRemovedProductCount: action.payload.count
        }
        
    }

    case PRODUCT_TYPES.PRODUCT_TAB_PANEL_CHANGE: {
      return {
          ...state,
          tabPanel: action.payload,
          loading: false
      }
    }

    case PRODUCT_TYPES.PRODUCT_FILTER_IS_OPEN: {
      localStorage.setItem('productFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case PRODUCT_TYPES.PRODUCT_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case PRODUCT_TYPES.GET_PRODUCTS_R_P: {
      return {
        ...state,
        loading: { ...state.loading, ...{ list: true } }
      }
    }

    case PRODUCT_TYPES.GET_PRODUCTS_N_P: {
      return {
        ...state,
        loading: { ...state.loading, ...{ list: true } }
      }
    }

    case PRODUCT_TYPES.PRODUCT_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: Object.assign({}, state.initialFilter, { model: "", name: "" })
      }
    }

    case PRODUCT_TYPES.PRODUCT_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: Object.assign({}, state.initialFilter, { 
          productIds: [],
          name: '',
          model: '',
          status: null,
          minQuantity: null,
          maxQuantity:  null,
          minPrice: null,
          maxPrice: null,
          stors: [],
          franchises: [],
          productGroupIds: [],
          brandIds: [],
          categoryIds: []
        })
      }
    }

    case PRODUCT_TYPES.CLEAR_PRODUCT_SEE_ALL: {
       return {
          ...state,
          productSelect: [],
          pagNotRemovedProductCount: 0
        }
    }

    case PRODUCT_TYPES.FETCH_PRODUCT_SEE_ALL: {

      const newProduct = []
      action.payload.data.forEach(product => {
        if (product.descriptions && product.descriptions[0] && product.descriptions[0].name) {

          let selectData = {
            key: product.productId,
            productId: product.productId,
            price: product.price,
            model: product.model,
            name: product.descriptions[0].name,
            descriptions: product.descriptions,
            imageMain: product.imageMain,
            brands: product.brands,
            categories: product.categories,
            currentPrice: product.price,
            value: product.productId, 
            groupChecked: !!(product.productGroups && product.productGroups.length),
            label: product.descriptions[0].name,
            mainImgUrl: product.imageMain && product.imageMain[0] && product.imageMain[0].image ? config.URL + product.imageMain[0].image.url: '',
            imgUrl: product.imageMain && product.imageMain[0] && product.imageMain[0].image ? config.IMAGEURL + product.imageMain[0].image.url: ''
          }


          selectData.options = []
          selectData.activeOptions = []
          product.productOptions.forEach((optionGroup) => {
            let optionGroupData = {
              key: product.productId,
              required: optionGroup.required,
              value: optionGroup.option.optionId,
              label: optionGroup.option.source,
              optionId: optionGroup.option.optionId,
              option: optionGroup.option
            }

            optionGroupData.values = []
            optionGroup.values.forEach((option) => {
              if (option.optionValue && option.optionValue.descriptions && option.optionValue.descriptions.length) {
                let optionData = {
                  ...option,
                  key: optionGroup.option.optionId,
                  optionValueId: option.optionValueId,
                  label: option.optionValue.descriptions[0].name,
                  value: option.optionValueId,
                  price: option.price,
                  pointsPrefix: option.pointsPrefix
                }
                optionGroupData.values.push(optionData)
              }
            })


            selectData.options.push(optionGroupData)
          })
          const {price, options} = calculateProductPrice(selectData);
          selectData.price = price;
          selectData.prevPrice = product.prevPrice; 
          selectData.activeOptions = options.map((option) => option.optionValueId);
          newProduct.push(selectData)
        }
      })

      let productSelect = [...state.productSelect, ...newProduct  ]

      if (action.search) {
        productSelect  = [ ...newProduct ] 
      } 

      productSelect = productSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
        ...state,
        productSelect: [
          ...productSelect
        ],
        pagNotRemovedProductCount: action.payload.count
      }
    }

    case PRODUCT_TYPES.EXPORT_PRODUCTS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case PRODUCT_TYPES.EXPORT_PRODUCTS_F: {
      return {
        ...state,
        loading: false,
        exportProducts: action.payload.data
      }
    }

    case PRODUCT_TYPES.EXPORT_PRODUCTS_R: {
      return {
        ...state,
        loading: false,
        error: action.payload.data
      }
    }

    case PRODUCT_TYPES.GET_PRODUCT_CATEGORIES_F: {
      return {
        ...state,
        categories: action.payload
      }
    }

    case PRODUCT_TYPES.GET_PRODUCT_BRANDS_F: {
      return {
        ...state,
        brands: {
          data: action.payload.data.map((brand => ({ ...brand, source: brand.name }))),
          count: action.payload.count
        }
      }
    }

    case PRODUCT_TYPES.GET_PAG_ALL_PRODUCTS_P: {
      return {
        ...state,
        importLoad: true
      }
    }

    case PRODUCT_TYPES.GET_PAG_ALL_PRODUCTS_F: {
      return {
        ...state,
        importLoad: false
      }
    }

    case PRODUCT_TYPES.GET_PAG_ALL_PRODUCTS_R: {
      return {
        ...state,
        importLoad: false
      }
    }

    case PRODUCT_TYPES.PRODUCT_DATA_MIN_MAX_F: {
      return {
          ...state,
          minMaxValues: action.payload
      }
    }

    default:
            return state;
  }
}


export { 
  allProduct, 
  allRemovedProduct, 
  product, 
  selectedProduct, 
  products 
}