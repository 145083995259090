const EQUIPMENT_PAG_F = 'EQUIPMENT_PAG_L_F';
const EQUIPMENT_PAG_R = 'EQUIPMENT_PAG_L_R';
const EQUIPMENT_SIZE_CHANGE = 'EQUIPMENT_SIZE_CHANGE'
const EQUIPMENT_PAGE_CHANGE = 'EQUIPMENT_PAGE_CHANGE'
const EQUIPMENT_FILETR_TABE_CHANGE = 'EQUIPMENT_FILETR_TABE_CHANGE'
const EQUIPMENT_UPDATE_F = 'EQUIPMENT_UPDATE_F'
const EQUIPMENT_UPDATE_R = 'EQUIPMENT_UPDATE_R'
const EQUIPMENT_CHECKBOX_CHANGE = 'EQUIPMENT_CHECKBOX_CHANGE'
const EQUIPMENT_BULK_ARCIVE_F = 'EQUIPMENT_BULK_ARCIVE_F'
const EQUIPMENT_BULK_DESTROY_F = 'EQUIPMENT_BULK_DESTROY_F'
const EQUIPMENT_BULK_DESTROY_R = 'EQUIPMENT_BULK_DESTROY_R'
const EQUIPMENT_BULK_ARCIVE_R = 'EQUIPMENT_BULK_ARCIVE_R'
const EQUIPMENT_TAB_PANEL_CHANGE = 'EQUIPMENT_TAB_PANEL_CHANGE'
const EQUIPMENT_CREATE_F = 'EQUIPMENT_CREATE_F'
const EQUIPMENT_CREATE_R = 'EQUIPMENT_CREATE_R'
const EQUIPMENT_GET_ONE_F = 'EQUIPMENT_GET_ONE_F'
const EQUIPMENT_GET_ONE_R = 'EQUIPMENT_GET_ONE_R'
const EQUIPMENT_REQUIRED_VALIDATION_F = 'EQUIPMENT_REQUIRED_VALIDATION_F'
const EQUIPMENT_REQUIRED_VALIDATION_R = 'EQUIPMENT_REQUIRED_VALIDATION_R'
const NEW_EQUIPMENT = 'NEW_EQUIPMENT'
const EQUIPMENT_DATA_FILTER = 'EQUIPMENT_DATA_FILTER'
const EQUIPMENT_DELETE_F = 'EQUIPMENT_DELETE_F'
const EQUIPMENT_DELETE_R = 'EQUIPMENT_DELETE_R'
const EQUIPMENT_LANGUAGE_PANEL_CHANGE = 'EQUIPMENT_LANGUAGE_PANEL_CHANGE'
const FETCH_EQUIPMENT_CONDITIONS_LIST_P = 'FETCH_EQUIPMENT_CONDITIONS_LIST_P'
const FETCH_EQUIPMENT_CONDITIONS_LIST_F = 'FETCH_EQUIPMENT_CONDITIONS_LIST_F'
const FETCH_EQUIPMENT_CONDITIONS_LIST_R = 'FETCH_EQUIPMENT_CONDITIONS_LIST_R'
const FETCH_EQUIPMENT_TYPES_LIST_P = 'FETCH_EQUIPMENT_TYPES_LIST_P'
const FETCH_EQUIPMENT_TYPES_LIST_F = 'FETCH_EQUIPMENT_TYPES_LIST_F'
const FETCH_EQUIPMENT_TYPES_LIST_R = 'FETCH_EQUIPMENT_TYPES_LIST_R'

export {
    EQUIPMENT_PAG_F,
    EQUIPMENT_PAG_R,
    EQUIPMENT_SIZE_CHANGE,
    EQUIPMENT_PAGE_CHANGE,
    EQUIPMENT_FILETR_TABE_CHANGE,
    EQUIPMENT_UPDATE_F,
    EQUIPMENT_UPDATE_R,
    EQUIPMENT_CHECKBOX_CHANGE,
    EQUIPMENT_BULK_ARCIVE_F,
    EQUIPMENT_BULK_DESTROY_R,
    EQUIPMENT_BULK_DESTROY_F,
    EQUIPMENT_BULK_ARCIVE_R,
    EQUIPMENT_TAB_PANEL_CHANGE,
    EQUIPMENT_CREATE_F,
    EQUIPMENT_CREATE_R,
    EQUIPMENT_GET_ONE_F,
    EQUIPMENT_GET_ONE_R,
    EQUIPMENT_REQUIRED_VALIDATION_F,
    EQUIPMENT_REQUIRED_VALIDATION_R,
    NEW_EQUIPMENT,
    EQUIPMENT_DATA_FILTER,
    EQUIPMENT_DELETE_F,
    EQUIPMENT_DELETE_R,
    EQUIPMENT_LANGUAGE_PANEL_CHANGE,
    FETCH_EQUIPMENT_CONDITIONS_LIST_P,
    FETCH_EQUIPMENT_CONDITIONS_LIST_F,
    FETCH_EQUIPMENT_CONDITIONS_LIST_R,
    FETCH_EQUIPMENT_TYPES_LIST_P,
    FETCH_EQUIPMENT_TYPES_LIST_F,
    FETCH_EQUIPMENT_TYPES_LIST_R
}