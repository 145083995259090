import {combineReducers} from 'redux'
import {dialog} from "./dialogReducers";
import {language, languages} from "./languageReducers";
import { globals } from './globalReducer'
import { userGroups } from './system/userGroupReducer'
import { users } from './system/userReducer'
import { timeTracking } from './system/timeTrackingReducer';
import { equipment } from './system/equpmentReducer';
import { customers } from './customer/customerReducer'
import { customerGroups } from './customer/customerGroupReducer'
import { currency } from './system/currencyReducer'
import {filter, deletedFilter, selectedFilter, filters} from "./catalog/filterReducer";
import {category, deletedCategory, selectedCategory, categories} from "./catalog/categoryReduser";
import {brands} from "./catalog/brandReducer";
import {allOptions, allRemovedOptions, option, selectedOption, options} from './catalog/optionsReducer'
import {allInformations,allRemovedInformations,information,selectedInformation,informations} from "./catalog/informationReducers";
import {manufacturers} from "./catalog/manufacturerReducer";
import {allAttributeGroup,allRemovedAttributeGroup,attributeGroup,selectedAttributeGroup, attributeGroups} from "./catalog/attributeGroupReduser";
import {allAttribute,allRemovedAttribute,attribute,selectedAttribute, attributes} from "./catalog/attributeReduser";
import {allRecurring,allRemovedRecurring,recurring,selectedRecurring} from "./catalog/recurringReducer";
import {allProduct,allRemovedProduct,product,selectedProduct,products} from "./catalog/productReducers"
import {productGroups} from "./catalog/productGroupReducer"
import { reviews } from "./catalog/reviewReducer"
import { discounts } from "./discount/discountReducer"
import { promoCodes } from "./discount/promoCodeReducer"
import {userList, userItem, selectedUser, usersFranchise, userRemovedList} from "./system/usersReducer"
import {allDoneOrder, allOrder, allRemovedOrder, allOrderList, newOrder, newOrders} from "./sales/orderReducers"
import coords from "./catalog/coordsReducer"
import {account} from "./catalog/accountReducers";

import {allDownloads,allRemovedDownloads,downloads,selectedDownloads} from "./catalog/downloadReducer";
import {allImages,allRemovedImages,images,selectedImages, img} from "./media/imageReducer";
import { icons } from "./media/iconReducer";
import { videos } from "./media/videoReducer";
import {allSlides,allRemovedSlides,slides,selectedSlides} from "./media/slideReducer";

import {chat} from "./chat/chatReducer";

import { validations } from "./catalog/validationReducer"
import { dataFilter } from './utils/filter/dataFilterReducer'

import { notifications } from './notificationReducers'

import { stors } from './store/storeReducer'
import { digitalCard } from './business/digitalCardReducer'
import { sections } from './store/sectionReducer'
import { pages } from './store/pageReducer'

import { promotion } from './promotion/promotionReducer'

import { business } from './business/businessReducer'
import { plan } from './business/planReducer'
import { payment } from './business/paymentReducer'

import { planSubscription } from './business/planSubscriptionReducer'

import { zips } from './store/zipReducer';

import { franchises } from "./store/franchiseReducer";

import { tickets } from './call/ticketReducer'

import { sms } from './call/smsReducer'

import { bulkSms } from './sms/smsReducer'

import { smsGroup } from './sms/smsGroupReducer'

import { campaign } from './sms/campaignReducer'

import { smsDomain } from './sms/smsDomainReducer'

import { smsPhone } from './sms/smsPhoneReducer'

import { bulkCall } from './bulkCall/callReducer'

import { callGroup } from './bulkCall/callGroupReducer'

import { call } from './call/callReducer'

import { links } from './store/linkReducer'
import { notificationTypes } from './notification/notificationTypeReducers'
import { notificationSounds } from './notification/notificationSoundReducers'
import { notificationTypeGroups } from './notification/notificationTypeGroupReducers'
import { notificationSettings } from './notification/notificationSettingsReducers'
import { cart } from './cartReducer'
import { files } from './fileReducers'
import { deliveryRoute } from './delivery/deliveryRouteReducer'
import { purchase } from './inventory/purchaseReducer'
import { supplier } from './inventory/supplierReducer'
import { warehouse } from './inventory/warehouseReducer'

const allReducers = combineReducers({
  dialog,
  
  language, languages,
  userGroups,
  users,
  /* --timeTracking---*/
  timeTracking,
  /* --equipment---*/
  equipment,
  /* --currency---*/
  currency,
  
  /*---filter---*/
  filter, deletedFilter, selectedFilter, filters,
  
  /*---category---*/
  category, deletedCategory, selectedCategory, categories,
  
  /*---options---*/
  allOptions, allRemovedOptions, selectedOption, option, options,
  
  /*---information---*/
  selectedInformation, information, allRemovedInformations, allInformations, informations,
  
  /*---manufacturer---*/
  manufacturers,
  
  /*---attribute group---*/ //
  allAttributeGroup, allRemovedAttributeGroup, attributeGroup, selectedAttributeGroup, attributeGroups,
  
  /*---attribute---*/ //
  allAttribute, allRemovedAttribute, attribute, selectedAttribute, attributes,
  
  
  
  /*---recurring---*/
  allRecurring, allRemovedRecurring, recurring, selectedRecurring,
  
  /*---product---*/
  allProduct, allRemovedProduct, product, selectedProduct, products,

  /*---productGroup---*/
  productGroups,
  
  /*---order---*/
  allDoneOrder, allOrder, allRemovedOrder, allOrderList, newOrder, newOrders,
  
  /*---coords---*/
  coords,
  
  /*---user---*/
  userList , userItem, selectedUser, usersFranchise, userRemovedList,
  
  /*---account---*/
  account,
  
  /*---media---*/
  
  /*---image---*/
  allImages,allRemovedImages,images,selectedImages, img,

  videos, icons,

  /*---downloads---*/
  allDownloads, allRemovedDownloads, downloads, selectedDownloads,
  /*---slide---*/
  allSlides,allRemovedSlides,slides,selectedSlides,

  /* --validation-- */
  validations,
  /* --dataFilter-- */
  dataFilter,
   /* --global-- */
  globals,
   /* --reviews-- */
  reviews,
  /* --discounts-- */
  discounts,
  /* --promoCodes-- */
  promoCodes,
  /* --customers--*/
  customers,
  /* --customerGroups--*/
  customerGroups,
  /* --brands--*/
  brands,
  /* --chat-- */
  chat,
  /* --notifications-- */
  notifications,
  /* --stors-- */
  stors,
  /* --digitalCard-- */
  digitalCard,
  /* --promotion-- */
  promotion,
  /* --sections-- */
  sections,
  /* --pages-- */
  pages,
  /* --business-- */
  business,
  /* --payment-- */
  payment,
  /* --planSubscription-- */
  planSubscription,
  /* --plan-- */
  plan,
  /* --zips-- */
  zips,
  /* --franchises--*/
  franchises,
  /* --tickets--*/
  tickets,
  /* --sms--*/
  sms,
  /* --bulkSms--*/
  bulkSms,
  /* --smsGroup--*/
  smsGroup,
  /* --smsDomain--*/
  smsDomain,
  /* --smsPhone--*/
  smsPhone,
  /* --campaign--*/
  campaign,
  /* --bulkCall--*/
  bulkCall,
  /* --smsGroup--*/
  callGroup,
  /* --call--*/
  call,
  /* --links--*/
  links,
  /*--notificationTypes--*/
  notificationTypes,
  /*--notificationSounds--*/ 
  notificationSounds,
  /*--notificationTypeGroups--*/
  notificationTypeGroups,
  /*--notificationSettings--*/
  notificationSettings,
  /*--cart--*/
  cart,
  /*--file--*/
  files,
  /**--deliveryRoute-- */
  deliveryRoute,
  /**--inventory-- */
  purchase, supplier, warehouse
});

export {allReducers}