import { EQUIPMENT_TYPES } from '../../types'
import EquipmentFields from '../../../system/UserEquipment/type'

const initialState = {
  data: [],
  navItem: new EquipmentFields().navBar,
  oneData: new EquipmentFields(),
  error: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedEquipmentPage') || 1,
    notRemoved: localStorage.getItem('notRemovedEquipmentPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedEquipmentPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedEquipmentPageSize') || 10
  },
  langTab : {
    equipmentId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  dataFilter: {
    name: '',
    nameF: null,
    code: false,
    loacle: false,
    equipmentId: false,
    sortOrder: false
  },
  tabPanel: '1',
  checked: false,
  ids: [],
  conditions: [],
  types: []
};



const equipment = ( state = initialState, action = {}) => {

  switch (action.type) {

    case EQUIPMENT_TYPES.NEW_EQUIPMENT: {
      return { 
        ...state,
        oneData: new EquipmentFields(),
        error: []
      }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_PAG_F: {

        return {
          ...state,
          data: action.payload.data.map((elem) => state.ids.includes(elem.equipmentId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.equipmentId)),
          count: action.payload.count
        }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.equipmentId) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case EQUIPMENT_TYPES.EQUIPMENT_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case EQUIPMENT_TYPES.EQUIPMENT_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case EQUIPMENT_TYPES.EQUIPMENT_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new EquipmentFields().navBar,
          error: []
      }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_REQUIRED_VALIDATION_R: {
      state.navItem = new EquipmentFields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new EquipmentFields().navBar
      }
    }

    case EQUIPMENT_TYPES.NEW_USER: {
      return { 
          ...state,
          oneData: new EquipmentFields(),
          navItem: new EquipmentFields().navBar,
          error: []
      }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case EQUIPMENT_TYPES.EQUIPMENT_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.equipmentId !== id)
      }
    }

    case EQUIPMENT_TYPES.FETCH_EQUIPMENT_CONDITIONS_LIST_F: {
      return {
          ...state,
          conditions: action.payload.map(elem => ({ value: elem.conditionId, label: elem.name }))
      }
    }

    case EQUIPMENT_TYPES.FETCH_EQUIPMENT_TYPES_LIST_F: {
      return {
          ...state,
          types: action.payload.map(elem => ({ value: elem.equipmentTypeId, label: elem.name }))
      }
    }
    
    default:
            return state;
  }
}
export {
  equipment
}