import  { VIDEO_TYPES } from '../../types'
import config from '../../../../config/app'
import { Notification } from 'element-react'
import { fileTypeValidation } from '../../../validation/validationField'
import axios from 'axios'

 class VideoActions {
    constructor(_props) {
        this.api = config['MEDIA']
        this.rest  = 'video'
        this.arcive = '/bulk'
        this.destroy = '/bulk/destroy'
        this.upl = 'upload'
        this.f = '/f'
        this.g = '/g'
        this.c = '/c'
        this.d = '/d'
        this.u = '/u'
        this.m = '/m'
        this.i = '/i'
    }


    getFolders = (jwt, name) => {
        return async dispatch => {
            try {
                const response = await axios.get(
                this.api + this.rest + this.f +this.g,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    })

                    dispatch({
                        type: VIDEO_TYPES.VIDEO_GET_FOLDERS_F,
                        payload: response.data,
                        dialogVisibleName: name
                    })

            } catch (e) {
                dispatch({
                    type: VIDEO_TYPES.VIDEO_GET_FOLDERS_R,
                    payload: e
                })
            }
        }
    }

    addFolder = (jwt, dir, name) => {
      return async dispatch => {
        try {
          const response = await axios.get(
              this.api + this.rest + this.f + this.c + `?dir=${dir}&&folder_name=${name}`,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })

            dispatch({
                type: VIDEO_TYPES.VIDEO_ADD_FOLDER_F,
                payload: response.data.dir
            })

        } catch (e) {

              dispatch({
                  type: VIDEO_TYPES.VIDEO_ADD_FOLDER_R,
                  payload: e.response.data
              })

          }
      }
  }

    removeFolder = (jwt, dir) => {
        return async dispatch => {
            try {
            
               await axios.get(
                  this.api + this.rest + this.f +this.d + `?dir=${dir}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                    }
                })

                dispatch({
                    type: VIDEO_TYPES.VIDEO_REMOVE_FOLDER_F,
                    payload: dir
                })

            } catch (e) {
                dispatch({
                    type: VIDEO_TYPES.VIDEO_REMOVE_FOLDER_R,
                    payload: e.response.data
                })
            }
        }
    }

    updateFolder = (jwt, oldPath, newPath, dir) => {
      return async dispatch => {
          try {
          
              const response = await axios.get(
                  this.api + this.rest + this.f +this.u + `?oldPath=${oldPath.substring(oldPath.lastIndexOf('/') + 1)}&&newPath=${newPath.substring(newPath.lastIndexOf('/') + 1)}&&dir=${dir} `,
              {
                  headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                  }
              })

              dispatch({
                  type: VIDEO_TYPES.VIDEO_UPDATE_FOLDER_F,
                  payload: response.data
              })

          } catch (e) {
              dispatch({
                  type: VIDEO_TYPES.VIDEO_REMOVE_FOLDER_R,
                  payload: e.response.data
              })
          }
      }
    }

    addVideo = (jwt, dir, files, Type, fileData, options) => {
      return async dispatch => {
        try {
            const invalidFiles = []
            const validFiles = []
            const formData = new FormData()
            files.forEach((file, i) => {
              if (fileTypeValidation(file.name, 'video')) {
                formData.append('file', file)
                formData.append(`fileData[${i}]`, JSON.stringify({ 
                  name: fileData[i].name, 
                  alt: fileData[i].alt, 
                  sort_order: fileData[i].sort_order,
                  stores: fileData[i].stores  
                }))
                validFiles.push(file)
              } else {
                invalidFiles.push({statusText: 'Unprocessable Entity', message: `Wrong file type found. Only ${file.name} video files are allowed!` })
              }
            })

            invalidFiles.length && invalidFiles.map((elem, index) => {
              Notification.error( {
                title: elem.statusText,
                message: elem.message
              }, 20 * index)
            })

            if (validFiles.length) {
              const response = await axios.request({
                method: "POST",
                url: this.api + this.rest + '/' + this.upl + `?dir=${dir}`,
                data: formData,
                headers: { Authorization: `Bearer ${jwt}` },
                onUploadProgress: options.onUploadProgress
              })

              dispatch({
                type: VIDEO_TYPES[Type + '_CREATE_F'],
                payload: response
              })
            }

        } catch(e) {
            Notification.error( {
              title: e.response.statusText,
              message: e.response.data.error
            }, 20) 
            dispatch({
                type: VIDEO_TYPES[Type + '_CREATE_R'],
                payload: e
            })
        }
      }
    }

    getVideos = (jwt, page, count, dir, filterTab, search) => {
      return async dispatch => {
        try {
            const response = await axios.get(
            this.api + this.rest + `/${page}/${count}?dir=${dir}&&deleted=${filterTab}&&name=${search || ''}`,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
          dispatch({
            type: VIDEO_TYPES.GET_VIDEOS_DIR_F,
            payload: response
          })

        } catch(e) {
            dispatch({
                type: VIDEO_TYPES.GET_VIDEOS_DIR_R,
                payload: e
            })
        }
      }
    }

    destroyVideo = (jwt, id, Type, filterTab, dir, _page, count) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
              type: VIDEO_TYPES[Type + '_DELETE_F'],
              payload: id
            })
            dispatch(this.getVideos(jwt, 1, count, dir, filterTab))
        } catch (e) {
            dispatch({
                type: VIDEO_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    moveDirectory = (jwt, body, from_dir, to_dir, filterTab, page, count) => {
      return async dispatch => {
        try {
          const response = await axios.put(
            this.api + this.rest + this.f + this.m  + `?from_dir=${from_dir}&&to_dir=${to_dir}`,
            body,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }

          )
            dispatch(this.getVideos(jwt, page, count, from_dir, filterTab))
            dispatch({
                type: VIDEO_TYPES.VIDEO_MOVE_DIRECTORY_F,
                payload: response
            })
        } catch (e) {
            dispatch({
                type: VIDEO_TYPES.VIDEO_MOVE_DIRECTORY_R,
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
    bulk = (jwt, body, filterTab, dir, _page, count) => {
      return async dispatch => {
        try {
           await axios.put(
            this.api + this.rest + this.arcive,
            body,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: VIDEO_TYPES.VIDEO_BULK_ARCIVE_F,
                payload: body
            })

            dispatch(this.getVideos(jwt, 1, count, dir, filterTab ))
            
        } catch (e) {
            dispatch({
                type: VIDEO_TYPES.VIDEO_BULK_ARCIVE_F,
                payload: e
            })

          }
        }
    }


    /**
     * @name bulkDestroy
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (jwt, body, filterTab, dir, _page, count) => {
      return async dispatch => {
        try {
           await axios.put(
            this.api + this.rest + this.destroy,
            body,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: VIDEO_TYPES.VIDEO_BULK_ARCIVE_F,
                payload: body
            })

            dispatch(this.getVideos(jwt, 1, count, dir, filterTab ))
            
        } catch (e) {
            dispatch({
                type: VIDEO_TYPES.VIDEO_BULK_ARCIVE_F,
                payload: e
            })

          }
        }
    }

      /**
    * @name MainVideo
    * @param elem
    * @returns {function(*)}
    */
   addVideoMain = video => {
    return dispatch => {
        dispatch({
          type: VIDEO_TYPES.ADD_MAIN_VIDEO,
          payload: [video]
        }) 
      }
  };

    setDialogVisible = value => 
        async dispatch => 
            dispatch({
               type: VIDEO_TYPES.VIDEO_DIALOG_VISIBLE,
               payload: value
            })
    
      changeInputFile = (file, url) => {
        return async dispatch => {
            dispatch({
                type: VIDEO_TYPES.VIDEO_CHANGE_FILE,
                payload: {
                    file: file,
                    url: url
                },
            })
        }
      }
  
      onRemoveChangedFile = (index) => {
          return async dispatch => {
              dispatch({
                  type: VIDEO_TYPES.VIDEO_REMOVE_CHANGED_FILE,
                  payload: index
              })
          }
      }
  
        /**
          * @name MainIcon
          * @param elem
          * @returns {function(*)}
        */
      changeDirectory = dir => {
        return dispatch => {
            dispatch({
              type: VIDEO_TYPES.VIDEO_CHANGE_DIRECTORY,
              payload: dir
            }) 
          }
      };
  
      clearHistory = () => {
        return dispatch => {
          dispatch({
            type: VIDEO_TYPES.VIDEO_CLEAR_UPLOAD_HISTORY,
          }) 
        }
      }
        
    
    }




export default new VideoActions()