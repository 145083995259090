import axios from 'axios'
import config from '../../../../../config/app.json'
import qs from 'qs';
    
const search = (value, jwt) => {

    return async dispatch => {

      try {

        const query = value

        const searchString = qs.stringify(query);

        let url = `${config['API']}c/p/search?${searchString}`

        const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
        })

        dispatch({
            type: 'DATA_FILTER_F',
            payload: response.data
        })
          
      } catch (e) {

            dispatch({
                type: 'DATA_FILTER_R',
                payload: e
            })
         
        }
    }
}


const minMaxValue = (jwt) => {

    return async dispatch => {

      try {

        let url = `${config['PRODUCT']}c/p/minmax`

        const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
        })

        dispatch({
            type: 'DATA_MIN_MAX_F',
            payload: response.data
        })
          
      } catch (e) {

            dispatch({
                type: 'DATA_MIN_MAX_R',
                payload: e
            })
         
        }
    }
}

const minMaxData = (jwt, route, query, actionType, API_KEY) => {

    return async dispatch => {

      try {
        const searchString = qs.stringify(query);
        let url = `${config[API_KEY]}${route}minmax?${searchString}`
        const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
        })

        dispatch({
            type: `${actionType}_DATA_MIN_MAX_F`,
            payload: response.data
        })
          
      } catch (e) {

            dispatch({
                type: `${actionType}_DATA_MIN_MAX_R`,
                payload: e
            })
         
        }
    }
}
export  {
    search, 
    minMaxValue,
    minMaxData
}