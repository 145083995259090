const TIME_TRACKING_PAG_F = 'TIME_TRACKING_PAG_L_F';
const TIME_TRACKING_PAG_R = 'TIME_TRACKING_PAG_L_R';
const TIME_TRACKING_SIZE_CHANGE = 'TIME_TRACKING_SIZE_CHANGE'
const TIME_TRACKING_PAGE_CHANGE = 'TIME_TRACKING_PAGE_CHANGE'
const TIME_TRACKING_FILETR_TABE_CHANGE = 'TIME_TRACKING_FILETR_TABE_CHANGE'
const TIME_TRACKING_UPDATE_F = 'TIME_TRACKING_UPDATE_F'
const TIME_TRACKING_UPDATE_R = 'TIME_TRACKING_UPDATE_R'
const TIME_TRACKING_CHECKBOX_CHANGE = 'TIME_TRACKING_CHECKBOX_CHANGE'
const TIME_TRACKING_BULK_ARCIVE_F = 'TIME_TRACKING_BULK_ARCIVE_F'
const TIME_TRACKING_BULK_DESTROY_F = 'TIME_TRACKING_BULK_DESTROY_F'
const TIME_TRACKING_BULK_DESTROY_R = 'TIME_TRACKING_BULK_DESTROY_R'
const TIME_TRACKING_BULK_ARCIVE_R = 'TIME_TRACKING_BULK_ARCIVE_R'
const TIME_TRACKING_TAB_PANEL_CHANGE = 'TIME_TRACKING_TAB_PANEL_CHANGE'
const TIME_TRACKING_CREATE_F = 'TIME_TRACKING_CREATE_F'
const TIME_TRACKING_CREATE_R = 'TIME_TRACKING_CREATE_R'
const TIME_TRACKING_GET_ONE_F = 'TIME_TRACKING_GET_ONE_F'
const TIME_TRACKING_GET_ONE_R = 'TIME_TRACKING_GET_ONE_R'
const TIME_TRACKING_REQUIRED_VALIDATION_F = 'TIME_TRACKING_REQUIRED_VALIDATION_F'
const TIME_TRACKING_REQUIRED_VALIDATION_R = 'TIME_TRACKING_REQUIRED_VALIDATION_R'
const NEW_TIME_TRACKING = 'NEW_TIME_TRACKING'
const TIME_TRACKING_DATA_FILTER = 'TIME_TRACKING_DATA_FILTER'
const TIME_TRACKING_DELETE_F = 'TIME_TRACKING_DELETE_F'
const TIME_TRACKING_DELETE_R = 'TIME_TRACKING_DELETE_R'
const TIME_TRACKING_LANGUAGE_PANEL_CHANGE = 'TIME_TRACKING_LANGUAGE_PANEL_CHANGE'

export {
    TIME_TRACKING_PAG_F,
    TIME_TRACKING_PAG_R,
    TIME_TRACKING_SIZE_CHANGE,
    TIME_TRACKING_PAGE_CHANGE,
    TIME_TRACKING_FILETR_TABE_CHANGE,
    TIME_TRACKING_UPDATE_F,
    TIME_TRACKING_UPDATE_R,
    TIME_TRACKING_CHECKBOX_CHANGE,
    TIME_TRACKING_BULK_ARCIVE_F,
    TIME_TRACKING_BULK_DESTROY_R,
    TIME_TRACKING_BULK_DESTROY_F,
    TIME_TRACKING_BULK_ARCIVE_R,
    TIME_TRACKING_TAB_PANEL_CHANGE,
    TIME_TRACKING_CREATE_F,
    TIME_TRACKING_CREATE_R,
    TIME_TRACKING_GET_ONE_F,
    TIME_TRACKING_GET_ONE_R,
    TIME_TRACKING_REQUIRED_VALIDATION_F,
    TIME_TRACKING_REQUIRED_VALIDATION_R,
    NEW_TIME_TRACKING,
    TIME_TRACKING_DATA_FILTER,
    TIME_TRACKING_DELETE_F,
    TIME_TRACKING_DELETE_R,
    TIME_TRACKING_LANGUAGE_PANEL_CHANGE
}