
import React, { Component } from 'react';
import config from '../../config/app'
import { connect }  from "react-redux";
import CallWindow from './Call/CallWindow';
import CallModal from './Call/CallModal';
import { chatActions } from "../redux-modules/actions";
import PeerConnection from './PeerConnection';
import _ from 'lodash';

class Call extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chatRoomID: '',
			callWindow: '',
			callModal: '',
			callFrom: null,
			localSrc: null,
			peerSrc: null,
			socketId: null
		};
		this.pc = {}
		this.startCallHandler = this.startCall.bind(this);
		this.endCallHandler = this.endCall.bind(this);
		this.rejectCallHandler = this.rejectCall.bind(this);
	}


    componentDidUpdate(prevProps) {
		const { callConfig, socket } = this.props
        if(socket && !prevProps.socket && socket !== prevProps.socket) {
			this.setupSocketListeners();
		}
		if(callConfig && callConfig !== prevProps.callConfig) {
			this.setState({ chatRoomID: callConfig.chatRoomID }, () => {
				this.startCallHandler(callConfig.isCaller, callConfig.chatRoomID, callConfig)
			})
        }
    }

  	setupSocketListeners = () => {

		const { socket } = this.props

		socket.on('request', ({ from: callFrom, to, socketId }) => {
			this.setState({ 
				callModal: 'active', 
				chatRoomID: to, 
				callFrom,
				socketId
			});
		})
		.on('call', async (data) => {
			if (data.sdp) {
				this.pc.setRemoteDescription(data.sdp);
				if (data.sdp.type === 'offer') this.pc.createAnswer(data.socketId);
			} else this.pc.addIceCandidate(data.candidate);
		})
    	.on('end', this.endCall.bind(this, false))
		.emit('init');
		
	};

	startCall(isCaller, targetUserID, callConfig) {
		const { user } = this.props
		const { socketId } = this.state;
		this.pc = new PeerConnection(targetUserID, this.props.socket, user, socketId, callConfig)
		.on('localStream', (src) => {
			const newState = { callWindow: 'active', localSrc: src };
			if (!isCaller) newState.callModal = '';
			this.setState(newState);
		})
		.on('peerStream', (src) => this.setState({ peerSrc: src }))
		.start(isCaller, callConfig);
	}

	rejectCall() {
		const { chatRoomID } = this.state;
		this.props.socket.emit('end', { to: chatRoomID });
		this.setState({ callModal: '' });
	}

	endCall(isStarter) {
		if (_.isFunction(this.pc.stop)) {
			this.pc.stop(isStarter);
		}
		this.pc = {};
		this.setState({
			callWindow: '',
			callModal: '',
			localSrc: null,
			peerSrc: null
		});
		this.props.addCallConfig(null)
	}

  render() {
	const { chatRoomID, callModal, callWindow, localSrc, peerSrc, callFrom } = this.state
    return (
		<div>
			{!_.isEmpty(this.props.callConfig && this.pc) && (
				<CallWindow
					status={callWindow}
					localSrc={localSrc}
					peerSrc={peerSrc}
					config={this.props.callConfig}
					mediaDevice={this.pc.mediaDevice}
					endCall={this.endCallHandler}
				/>
			) }
			{!_.isEmpty(callFrom) && (
				<CallModal
					status={callModal}
					rejectCall={this.rejectCallHandler}
					addCallConfig={this.props.addCallConfig}
					chatRoomID={chatRoomID}
					contactUser={callFrom.username ? callFrom.username : callFrom.info ? callFrom.info.firstName : 'Anonymous'}
					userAvatar={callFrom.avatar ? config['MEDIA'] + 'files/download?file=' + config['FILE_UPLOAD_UPL_TH'] + callFrom.avatar.creator + '/small/' + callFrom.avatar.name : ''}
				/>
			)}
		</div>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		user: state.account.user,
		socket: state.chat.socket,
		callConfig: state.chat.callConfig
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addCallConfig: (data) => dispatch ( chatActions.addCallConfig(data) )
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Call);

