import axios from 'axios'
import qs from 'qs';

class Service {
  constructor(api, all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes, relation, filter) {
    this.api = api;
    this.all = all;
    this.allR = allR;
    this.rest = rest;
    this.pagNotRem = pagNotRem; 
    this.pagRem = pagRem;
    this.bulkDel = bulkDel;
    this.bulkRec = bulkRec;
    this.bulkDes = bulkDes;
    this.relation = relation
  }
  
  getAll(jwt) {
    const url = `${this.api}${this.all}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  getAllRemoved(jwt) {
    const url = `${this.api}${this.allR}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`,
        'Cache-Control': 'no-cache', // Disables caching at the HTTP level
        'Pragma': 'no-cache'
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getAllNotRemoved(jwt) {
    const url = `${this.api}${this.all}`
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`,
        'Cache-Control': 'no-cache', // Disables caching at the HTTP level
        'Pragma': 'no-cache'
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  getItem(id, jwt) {
    const url = `${this.api}${this.rest}/${id}`;
    return fetch(url, {
      method: 'Get',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  create(body, jwt) {
    const url = `${this.api}${this.rest}`;
    return axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${jwt}`
        }
      })
  }
  
  edit(body, id, jwt) {
    const url = `${this.api}${this.rest}/${id}`;
    return axios.put(url, body, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    })
  }
  
  remove(id, jwt) {
    const url = `${this.api}${this.allR}/${id}`;
    return fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  recover(id, jwt) {
    const url = `${this.api}${this.all}/${id}`;
    return fetch(url, {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  getPaginationAll(jwt, page, count, query, body) {
    const searchString = qs.stringify(query)
    let url = `${this.api}c/p/a/${page}/${count}?${searchString}`;
    return axios(url, {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      },
      data: body
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.data
      }
    })
  }

  getPaginationNotRemoved(jwt, page, count, query) {
    const searchString = qs.stringify(query)
    let url = `${this.api}${this.pagNotRem}/${page}/${count}?${searchString}`;
    return axios(url, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.data
      }
    })
  }
  
  getPaginationRemoved(jwt, page, count, query) {
    const searchString = qs.stringify(query)
    let url =`${this.api}${this.pagRem}/${page}/${count}?${searchString}`;
    return axios(url, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.data
      }
    })
  }

  pagination(jwt, params, page, count, query) {
    const searchString = qs.stringify(query)
    const url =`${this.api}${this.rest}${params}/${page}/${count}?${searchString}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }


  bulkDelete(ids, jwt) {
    const url = `${this.api}${this.bulkDel}`;
    return fetch(url, {
      method: 'POST',
      body:  JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  bulkDestroy(ids, jwt) {
    const url = `${this.api}${this.bulkDes}`;
    return fetch(url, {
      method: 'POST',
      body:  JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  bulkRecover(ids, jwt) {
    const url = `${this.api}${this.bulkRec}`;
    return fetch(url, {
      method: 'POST',
      body:  JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getWithRelation(query, jwt) {
    const url = `${this.api}${this.rest}/${this.relation}?ids=[${query.ids}]&&deleted=${query.deleted}`
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  destroy(jwt, id) {
    const url = `${this.api}${this.rest}/${id}`;
    return axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    })
  }

  preview(jwt, id) {
    const url = `${this.api}${this.rest}/preview/${id}`;
    return axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${jwt}`
        }
      })
  }

  getTypes (jwt) {
    const url = `${this.api}${this.rest}/types`;
    return axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${jwt}`
        }
      })
  }

  getFilters(jwt, page, count, query) {
    const searchString = qs.stringify(query)
    let url = `${this.api}${'c/p/g/f'}/${page}/${count}?${searchString}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
}

export {Service}