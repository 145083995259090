import React, { useState, useEffect, useRef } from 'react';
import {
    FormGroup, 
    Label
  } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { showInfoDialogWithMessage } from './Utils'
import {orderActions} from "../../redux-modules/actions";
import {hideModal} from "../../redux-modules/actions/dialogActions";
import Select from 'react-select';

const CancelOrderDialog = (_props) => {
    const dispatch = useDispatch()
    const [, forceUpdate] = useState()
    const { data } = useSelector((reduxState) => reduxState.dialog)

    const { cancellationReasons } = useSelector((reduxState) => reduxState.newOrders)
    const account = useSelector((reduxState) => reduxState.account)
    const [disabled, setDisabled] = useState(true)
    const [state, setState] = useState({
        value: '',
        rows: 10,
        minRows: 10,
        maxRows: 10
    })

    const [errors, setError] = useState({})

    const isFirstRender = useRef(true)

    useEffect(() => {
        // Skip the first render
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
    
        const { orderCancellationReasonId } = data;
        const trimmedValue = state.value?.trim();
    
        // Determine if the form should be disabled
        const shouldEnableForm = (
            orderCancellationReasonId && orderCancellationReasonId !== 8
        ) || (
            orderCancellationReasonId && trimmedValue && trimmedValue.length > 3
        ) || (
            data.status === 'stolen' && !orderCancellationReasonId && trimmedValue && trimmedValue.length > 3
        );
    
        setDisabled(!shouldEnableForm);
    
        // Validate form if a specific reason is selected
        if (orderCancellationReasonId === 8 || data.status === 'stolen') {
            validateForm();
        } else if (orderCancellationReasonId) {
            setError({ value: '' })
        }
    }, [state.value, data.orderCancellationReasonId]);

    const validateForm = () => {
        let err = {}
        
        if (!state["value"] || !state["value"].replace(/^\s+|\s+$/g, '')) {
            err["value"] = "*Please enter notes.";
        } else if (state["value"].length < 4) {
            err["value"] = "*Minimum characters are 4";
        }

        setError(err)
    }

    const handleChange = (event) => {
		const textareaLineHeight = 24;
		const { minRows, maxRows } = state;
		
		const previousRows = event.target.rows;
  	    event.target.rows = minRows; 
		
		const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);
    
        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }
		
		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}
    
        setState({
            ...state,
            value: event.target.value,
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
    };


    useEffect(() => {
        if (data.status === 'cancel') {
            dispatch(orderActions.getAllOrderCancellationReasons(account.token));
        } else {
            validateForm();
        }
    }, [])

    const selectCancellationReasons= (selectedOption) => {
        data.orderCancellationReasonId = selectedOption ? selectedOption.value : null
        forceUpdate({})
    }

    return (
        <div className="modal-register">
            <h4 className="cancel-order__title">{data.status} order</h4>
            <form className="form cancel-order">
                <div className="form__group">
                    {
                        data.status === 'cancel' &&
                        <FormGroup>
                            <Label className='h6'>{'Cancellation Reasons'}</Label>
                            <Select
                                name="form-field-name"
                                value={data.orderCancellationReasonId}
                                multi = {false}
                                onChange={selectCancellationReasons}
                                options={cancellationReasons}
                            />
                        </FormGroup>
                    }
                    <FormGroup>
                        <Label className={data.orderCancellationReasonId === 8 || data.status === 'stolen' ? 'h6 required-field' : 'h6'}>{'Notes'}</Label>
                        <textarea
                            value={state.value}
                            placeholder={'Enter your cancel reason...'}
                            onChange={handleChange}
                            className="cancel-order__textarea"
                        />
                    </FormGroup>
                    {!!errors.value && <div className="validation">{errors.value}</div>}
                </div>
            </form>
            <button className="btn modal-button"
                    style={{backgroundColor: '#000', opacity: disabled ? "0.4" : "1"}}
                    type={'submit'}
                    disabled={disabled}
                    form={'change-password'}
                    onClick={_e => 
                        dispatch(orderActions.changeStatus(data.id, {...data, message: state.value}, account.token)).then(_payload => {
                            showInfoDialogWithMessage(dispatch, {message: `Your order ${data.status}`})
                            setTimeout(() => dispatch(hideModal()), 5000)
                        })
                    }
                >
                    Confirm
            </button>
        </div>
    )
}

export default CancelOrderDialog;