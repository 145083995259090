import  { STORE_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import {Notification} from 'element-react'
const flowToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJyb290X2lkIjoyNTYsInVzZXJuYW1lIjoiYXRvbSIsInJvbGVzIjpbImFkbWluIl19.1NSakO7VEAaOgGkQJlhXhDrDyRRLGKG6i1DNXt5vfNqiKlvYUUVnQtx1-2Vff3O2'

 class StoreActions {
  constructor(_props) {
    this.api = config['STORE']
    this.rest  = 'c/'
    this.arcive = 'b/',
    this.scrapeApi = config['SCRAPING_API']
    this.scrapeRest = 'weedmaps/products'
  }

  pagination = (jwt, params, page, count, query) => {
    return async dispatch => {
      try {
        const searchString = qs.stringify(query)
        const response = await axios.get(
        this.api + this.rest + `s/${params}` + page + '/' + count + '?' + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })
        response.data.data = response.data.data.map(item => ({...item, businessName: item.business ? item.business.name: ""}))
        dispatch({
          type: STORE_TYPES.STORE_PAG_F,
          payload: response.data
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.STORE_PAG_R,
          payload: e
        })

      }
    }
  }

  fetchPaymentMethods = (jwt) => {
    return async dispatch => {
      try {
        const response = await axios.get(
          `${this.api}payment_method/l`,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          }
        )

        dispatch({
          type: STORE_TYPES.STORE_FETCH_PAYMENT_METHODS_F,
          payload: response.data
        })

      } catch (e) {
        dispatch({
          type: STORE_TYPES.STORE_FETCH_PAYMENT_METHODS_R,
          payload: e
        })

      }
    }
  }

  minMaxCallCount = (jwt, query) => {
    return async dispatch => {
      try {
        const searchString = qs.stringify(query)

        const response = await axios.get(
        this.api + this.rest + `s/v/m/minmax/call_count?` + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: STORE_TYPES.FETCH_STORE_CALL_MIN_MAX_F,
          payload: response.data
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.FETCH_STORE_CALL_MIN_MAX_R,
          payload: e
        })

      }
    }
  }

  minMaxCustomerCount = (jwt, query) => {
    return async dispatch => {
      try {
        const searchString = qs.stringify(query)

        const response = await axios.get(
        this.api + this.rest + `s/v/m/minmax/customer_count?` + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: STORE_TYPES.FETCH_STORE_CUSTOMER_MIN_MAX_F,
          payload: response.data
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.FETCH_STORE_CUSTOMER_MIN_MAX_R,
          payload: e
        })

      }
    }
  }

  minMaxOrderCount = (jwt, query) => {
    return async dispatch => {
      try {
        const searchString = qs.stringify(query)

        const response = await axios.get(
        this.api + this.rest + `s/v/m/minmax/order_count?` + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: STORE_TYPES.FETCH_STORE_ORDER_COUNT_MIN_MAX_F,
          payload: response.data
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.FETCH_STORE_ORDER_COUNT_MIN_MAX_R,
          payload: e
        })

      }
    }
  }

  minMaxDoneOrderCount = (jwt, query) => {
    return async dispatch => {
      try {
        const searchString = qs.stringify(query)

        const response = await axios.get(
        this.api + this.rest + `s/v/m/minmax/done_order_count?` + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: STORE_TYPES.FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_F,
          payload: response.data
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_R,
          payload: e
        })

      }
    }
  }

  minMaxOrderTotal = (jwt, query) => {
    return async dispatch => {
      try {
        const searchString = qs.stringify(query)

        const response = await axios.get(
        this.api + this.rest + `s/v/m/minmax/order_total?` + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: STORE_TYPES.FETCH_STORE_ORDER_TOTAL_MIN_MAX_F,
          payload: response.data
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.FETCH_STORE_ORDER_TOTAL_MIN_MAX_R,
          payload: e
        })

      }
    }
  }


  seeAll = (jwt, page, count, query, search) => {
    return async dispatch => {
      try {

        dispatch({
          type: STORE_TYPES.FETCH_STORE_SEE_ALL_P
        })

        const searchString = qs.stringify(query)
        const response = await axios.get(
        this.api + this.rest + `s/r/` + page + '/' + count + '?' + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
          }
        })

        dispatch({
          type: STORE_TYPES.FETCH_STORE_SEE_ALL_F,
          payload: response.data,
          search: search
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.FETCH_STORE_SEE_ALL_R,
          payload: e
        })

      }
    }
  }

  update = (jwt, body, Type, history, _discard, verified) => {
    return async dispatch => {
      try {
        const response = await axios.put(
        this.api + this.rest + `s/${body.storeId}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: STORE_TYPES[Type + '_UPDATE_F'],
          payload: body
        })

        if (response.data.verified !== verified) {

          dispatch(this.verify(jwt, { storeId: response.data.storeId })) 
          
        }

        if (response.data.status) {
          dispatch(this.addNginx(flowToken, response.data.storeId))
        } else {
          dispatch(this.removeNginx(flowToken, response.data.storeId))
        }
          
        history.goBack()
          
      } catch (e) {

        if (Array.isArray(e.response.data)) {
          e.response.data.forEach(err => {
            Notification.error({
              title: err.path,
              message: err.msg
            }, 20)
          })
        } 

        dispatch({
            type: STORE_TYPES[Type + '_UPDATE_R'],
            payload: e
        })

      }
    }
  }

  destroy = (jwt, id, Type) => {
    return async dispatch => {
      try {
        await axios.delete(
          this.api + this.rest + `s/${id}`,
        {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
        }

        )
          dispatch({
              type: STORE_TYPES[Type + '_DELETE_F'],
              payload: id
          })
      } catch (e) {
          dispatch({
              type: STORE_TYPES[Type + '_DELETE_R'],
              payload: e
          })
        }
      }
  }

  getOne = (jwt, id, Type) => {
    return async dispatch => {
      try {
        const response = await axios.get(
          this.api + this.rest + `s/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }
        )
        dispatch({
            type: STORE_TYPES[Type + '_GET_ONE_F'],
            payload: response.data
        })

        return response.data
      } catch (e) {
          dispatch({
              type: STORE_TYPES[Type + '_GET_ONE_R'],
              payload: e
          })
          return e.response
        }
      }
  }

  getAddress = (jwt, id, Type) => {
    return async dispatch => {
      try {
        const response = await axios.get(
          this.api + this.rest + `s/address/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }
        )
        dispatch({
            type: STORE_TYPES[Type + '_GET_ADDRESS_F'],
            payload: response.data
        })

        return response.data
      } catch (e) {
          dispatch({
              type: STORE_TYPES[Type + '_GET_ADDRESS_R'],
              payload: e
          })
          return e.response
        }
      }
  }

  /**
   * @name bulk
   * @param elem
   * @returns {function(*)}
   */
    bulk = (ids, jwt, params, otherDispatchParams) => {
      return async dispatch => {
        try {
            await axios.post(
            this.api + this.rest + 's/' + this.arcive + params,
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: STORE_TYPES.STORE_BULK_ARCIVE_F,
                payload: ids
            })

            dispatch(this.pagination(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: STORE_TYPES.STORE_BULK_ARCIVE_R,
                payload: e
            })

          }
        }
    }
  /**
    * @name bulk
    * @param elem
    * @returns {function(*)}
  */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
          await axios.post(
            this.api + this.rest + 's/b/d',
            ids,
            {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          }
          )
            dispatch({
                type: STORE_TYPES.STORE_BULK_DESTROY_F,
                payload: ids
            })

            dispatch(this.pagination(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: STORE_TYPES.STORE_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

    create = (jwt, body, Type, history, _discard) => {
    return async dispatch => {
        await axios.post(
            this.api + this.rest + 's',
            body,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
          .then(response => {

            dispatch({
              type: STORE_TYPES[Type + '_CREATE_F'],
              payload: response.data
            })

            const { storeId } = response.data

            dispatch(this.verify(jwt, { storeId: storeId }))

            if (response.data.status) {
              dispatch(this.addNginx(flowToken, response.data.storeId))
            } else {
              dispatch(this.removeNginx(flowToken, response.data.storeId))
            }

            history.goBack()

          })
          .catch(e => {

            if (Array.isArray(e.response.data)) {
              e.response.data.forEach(err => {
                Notification.error({
                  title: err.path,
                  message: err.msg
                }, 20)
              })
            } 

            dispatch({
                  type: STORE_TYPES[Type + '_CREATE_R'],
                  payload: e
              })

          })
        }
    }

  verify = (jwt, body) => {
    return async dispatch => {
      try {

        dispatch({
          type: STORE_TYPES.STORE_VERIFY_P
        })

        const { data } = await axios.put(
          this.api + this.rest + `synchronize/${body.storeId}`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          }
        )

        dispatch({
          type: STORE_TYPES.STORE_VERIFY_F,
          payload: data
        })

      } catch (e) {

        dispatch({
          type: STORE_TYPES.STORE_VERIFY_R,
          payload: e
        })

      }
    }
  }

  addNginx = (jwt, storeId) => {
    return async dispatch => {
      try {

        dispatch({
          type: STORE_TYPES.STORE_ADD_DOMAIN_P
        })

        const rawResponse = await fetch(`https://reg.projectnulla.info/api/nginx/store/${storeId}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
          },
          body: {}
        });

        const content = await rawResponse.json();

        dispatch({
          type: STORE_TYPES.STORE_ADD_DOMAIN_F,
          payload: content
        })

      } catch (e) {
        dispatch({
          type: STORE_TYPES.STORE_ADD_DOMAIN_R,
          payload: e
        })

      }
    }
  }

  removeNginx = (jwt, storeId) => {
    return async dispatch => {
      try {

        dispatch({
          type: STORE_TYPES.STORE_ADD_DOMAIN_P
        })

        const rawResponse = await fetch(`https://reg.projectnulla.info/api/nginx/${storeId}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
          },
        });

        const content = await rawResponse.json();

        dispatch({
          type: STORE_TYPES.STORE_ADD_DOMAIN_F,
          payload: content
        })

      } catch (e) {
        dispatch({
          type: STORE_TYPES.STORE_ADD_DOMAIN_R,
          payload: e
        })

      }
    }
  }

  clearState = () => {
    return async dispatch => {
      dispatch({
        type: 'CLEAR_STORE_STATE'
      })
    }
  }

  updateProducts = (jwt, id, Type, history) => {
    return async dispatch => {
      try {
        await axios.post(
        this.scrapeApi + this.scrapeRest,
        {
          storeId: id
        },
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: STORE_TYPES[Type + '_UPDATE_PRODUCTS_F'],
        })
          
        history.goBack()
          
      } catch (e) {
        if (Array.isArray(e.response.data)) {
          e.response.data.forEach(err => {
            Notification.error({
              title: err.path,
              message: err.msg
            }, 20)
          })
        } 

        dispatch({
          type: STORE_TYPES[Type + '_UPDATE_PRODUCTS_R'],
          payload: e
        })

      }
    }
  }

}


export default new StoreActions()