import React, {Component} from 'react'
import { updateDimensions } from '../../../redux-modules/services/mobileService'
/* Pagination Component 
-------------------------------------------------*/
const isMobile = updateDimensions()

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            pager: {

            } 
        };
    }

    componentWillMount() {
        const { currentPage } = this.props
        // set page if items array isn't empty
        let { total, pageSize } = this.props

        // get new pager object for specified page
        let pager = this.getPager(total, currentPage, parseInt(pageSize));

        // update state
        this.setState({ pager: pager });
    }

    componentDidUpdate(prevProps, _prevState) {
        const { currentPage, total, pageSize } = this.props

        // reset page if items array has changed
        if ((total !== prevProps.total) || (pageSize !== prevProps.pageSize) || (currentPage !== prevProps.currentPage)) {
            this.setPage(currentPage);
        }
    }
    
    setPage(page) {
        let { total, pageSize } = this.props

        // get new pager object for specified page
        let pager = this.getPager(total, page, parseInt(pageSize));

        // update state
        this.setState({ pager: pager });

        // call change page function in parent component
        this.props.onCurrentChange(page);
    }

    getPager(totalItems, currentPage, pageSize) {
        let prev = 2
        let next = 2
        let showedCount = 5
        if (isMobile) {
            showedCount = 3
            prev = 1
            next = 1
        }
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage, endPage;
        if (totalPages <= showedCount) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= showedCount - 1) {
                startPage = 1;
                endPage = showedCount;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - showedCount;
                endPage = totalPages;
            } else {
                startPage = currentPage - prev;
                endPage = currentPage + next;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        const { pager } = this.state
        const { currentPage } = this.props

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }
        
        return (
            <ul className="pagination">
                {
                    !!(currentPage !== 1 && !isMobile) &&
                    <button 
                        className={pager.currentPage === 1 ? 'btn-prev disabled' : 'btn-prev'}
                        onClick={() => this.setPage(pager.currentPage - 1)}
                    >
                        <i className="el-icon el-icon-arrow-left"></i>
                    </button>
                }
                <button 
                    className={pager.currentPage === 1 ? 'page-number active' : 'page-number'}
                    onClick={() => this.setPage(1)}
                >
                    <a>1</a>
                </button>
                {
                    (pager.startPage > 2) &&
                    <span>...</span>
                }
                {pager.pages.map((page, index) =>
                    (page !== pager.totalPages && page !== 1) &&
                    <button 
                        onClick={() => this.setPage(page)}
                        key={index} className={pager.currentPage === page ? 'page-number active' : 'page-number'}
                    >
                        <a>{page}</a>
                    </button> 
                )}
                {
                    (pager.endPage + 1 !== pager.totalPages) && (pager.endPage !== pager.totalPages) &&
                    <span>...</span>
                }
                <button 
                    className={pager.currentPage === pager.totalPages ? 'page-number active' : 'page-number'}
                    onClick={() => this.setPage(pager.totalPages)}
                >
                    <a>{pager.totalPages}</a>
                </button>
                {
                    (currentPage !== pager.totalPages) &&
                    <button 
                        className={pager.currentPage === pager.totalPages ? 'btn-next disabled' : 'btn-next'}
                        onClick={() => this.setPage(pager.currentPage + 1)}
                    >
                        <i className="el-icon el-icon-arrow-right"></i>
                    </button>
                }
            </ul>
        );
    }
}

export default Pagination
