import { WAREHOUSE_TYPES } from '../../types'
import Fields from '../../../inventory/Warehouse/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  warehouseSelect: [],
  navItem: new Fields().navBar,
  inputFilters: new Fields().dataFilter,
  oneData: new Fields(),
  error: [],
  count: 0,
  isOpen: isMobile ? false : localStorage.getItem('warehouseFilter') == 'true' ? true : false,
  isOpenProductFilter: isMobile ? false : localStorage.getItem('warehouseProductFilter') == 'true' ? true : false,
  pageNumber: {
    removed: localStorage.getItem('removedWarehousePage') || 1,
    notRemoved: localStorage.getItem('notRemovedWarehousePage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedWarehousePageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedWarehousePageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    name: '',
    address: ''
  },
  dataFilter: {
    search: '',
    warehouseId: null,
  },
  loading: false,
  tabPanel: '1',
  checked: false,
  ids: [],
  productsFilter: {
    name: '',
    brandIds: []
  },
  products: [],
  productsCount: 0,
  statistics: {}
}

const warehouse = ( state = initialState, action = {}) => {

  switch (action.type) {

    case WAREHOUSE_TYPES.WAREHOUSE_PAG_F: {
      return {
        ...state,
        loading: false,
        data: action.payload.data.map((elem) => state.ids.includes(elem.warehouseId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
        checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.warehouseId)),
        count: action.payload.count
      }
    }


    case WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_PAG_F: {
      return {
        ...state,
        loading: false,
        products: action.payload.data,
        productsCount: action.payload.count
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
        ...state,
        showEntries: showEntries
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_PAGE_CHANGE: {
      const pageNumber = Object.assign(state.pageNumber, action.payload)
      return {
          ...state,
          pageNumber: pageNumber
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_FILETR_TABE_CHANGE: {
      return {
        ...state,
        filterTab: action.payload,
        checked: false
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_TAB_PANEL_CHANGE: {
      return {
        ...state,
        tabPanel: action.payload
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
        if (multiple) {
          elem['isChecked'] = checked
          return elem
        } else {
          state.data[key]['isChecked'] = checked
          return elem
        }
      })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.warehouseId) })
      
      return {
        ...state,
        data: data,
        checked: !multiple ? data.every(element => element.isChecked): checked,
        ids: ids
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_BULK_ARCIVE_F: {
      
      return { 
        ...state,
        checked: false
      }

    }

    case WAREHOUSE_TYPES.WAREHOUSE_BULK_DESTROY_F: {
      
      return { 
        ...state,
        checked: false
      }

    }

    case WAREHOUSE_TYPES.WAREHOUSE_LANGUAGE_PANEL_CHANGE: {
      return { 
        ...state,
        langTab: action.payload,
        ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
      }
    }


    case WAREHOUSE_TYPES.WAREHOUSE_CREATE_R: {
      return { 
        ...state,
        error: action.payload.response
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_GET_ONE_F: {
      if (!action.payload.address) {
        action.payload.address = new Fields().address
      } else if (!action.payload.address[0].location) {
        action.payload.address[0].location = {
          lat: new Fields().address[0].location.lat,
          lng: new Fields().address[0].location.lng
        }
      } else {
        action.payload.address[0].location = {
          lat: action.payload.address[0].location.coordinates[0],
          lng: action.payload.address[0].location.coordinates[1]
        }
      }
      
      return { 
        ...state,
        oneData: action.payload,
        navItem: new Fields().navBar,
        error: []
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_REQUIRED_VALIDATION_R: {
      state.navItem = new Fields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
        state.navItem[parseInt(elem.tab) - 1].count++
        state.navItem[parseInt(elem.tab) - 1].color = 'red'
        state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
          if (val.field === elem.name) {
            val['color'] = 'red'
          }
        })
      })
      return { 
        ...state,
        error: action.payload.errorMsg,
        tabPanel: tabPanel
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_REQUIRED_VALIDATION_F: {
      return { 
        ...state,
        error: [],
        navItem: new Fields().navBar
      }
    }

    case WAREHOUSE_TYPES.NEW_WAREHOUSE: {
      return { 
        ...state,
        oneData: new Fields(),
        navItem: new Fields().navBar,
        products: [],
        productsCount: 0,
        statistics: {},
        error: []
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_DATA_FILTER: {
      return { 
        ...state,
        dataFilter: { [action.payload['key']]: action.payload['value'] },
        error: []
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_DATA_FILTER: {
      return { 
        ...state,
        productsFilter: {
          name: state.productsFilter.name,
          dateFrom: state.productsFilter.dateFrom,
          dateTo: state.productsFilter.dateTo,
          brandIds: state.productsFilter.brandIds,
          [action.payload['key']]: action.payload['value'] 
        },
        error: []
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_DATA_FILTER_SEARCH: {
      return { 
        ...state,
        initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
        error: []
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH: {
      return { 
        ...state,
        productsFilter: Object.assign({}, state.productsFilter, { [action.payload['key']]: action.payload['value'] }),
        error: []
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_DELETE_F: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.filter(item => item.warehouseId !== id)
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_FILTER_IS_OPEN: {
      localStorage.setItem('warehouseFilter', !state.isOpen)
      return {
        ...state,
        isOpen: !state.isOpen
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_FILTER_IS_OPEN: {
      localStorage.setItem('warehouseProductFilter', !state.isOpenProductFilter)
      return {
        ...state,
        isOpenProductFilter: !state.isOpenProductFilter
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
        ...state,
        initialFilter: Object.assign({}, state.initialFilter, { 
          name: '',
          adderss: '',
          businessIds: []
        }),
        error: []
      }
    }

    case WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
        ...state,
        productsFilter: Object.assign({}, state.productsFilter, { 
          name: '',
          dateFrom: null,
          dateTo: null
        }),
        error: []
      }
    }

    case WAREHOUSE_TYPES.FETCH_WAREHOUSE_SEE_ALL_F: {
      const newWarehouses = []
      action.payload.data.forEach(warehouse => {
        newWarehouses.push({ 
          value: warehouse.warehouseId, 
          label: warehouse.name
        })
      })

      let warehouseSelect = [...state.warehouseSelect, ...newWarehouses]
      warehouseSelect = warehouseSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          warehouseSelect: warehouseSelect,
          count: action.payload.count
      }
    }

    case 'CLEAR_WAREHOUSE_STATE': {
      return {
        ...state,
        warehouseSelect: [],
        count: 0
      }
    }


    case WAREHOUSE_TYPES.GET_WAREHOUSE_STATISTICS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case WAREHOUSE_TYPES.GET_WAREHOUSE_STATISTICS_F: {
      return {
        ...state,
        statistics: action.payload
      }
    }

    case WAREHOUSE_TYPES.GET_WAREHOUSE_STATISTICS_R: {
      return {
        ...state,
        error: action.payload.data
      }
    }
    
    default:
        return state;
  }
}
export {
  warehouse
}