class Nav {

  getData() {
    const items = [
      /*___________________________________________*/
      {
        title: true,
        name: 'Project Nulla',
        wrapper: {            
          element: '',        
          attributes: {}    
        },
        class: ''             
      },
      {
        name: "Catalog",
        url: '/catalog',
        icon: "icon-layers",
        children: [
          {
            name: 'Categories',
            url: '/catalog/category',
            icon: 'fa fa-navicon',
          },
          {
            name: "Products",
            url: '/catalog/product',
            icon: "fa fa-tags",
            children: [
              {
                name: 'Product',
                url: '/catalog/product',
                icon: 'fa fa-product-hunt',
              },
              {
                name: 'Product Groups',
                url: '/catalog/product_group',
                icon: 'fa fa-tag',
              },
            ]
          },
          {
            name: 'Brands',
            url: '/catalog/brand',
            icon: 'fa fa-bandcamp',
          },
          {
            name: 'Recurring Profiles',
            url: '/catalog/recurring_profiles',
            icon: 'fa fa-clipboard',
          },
          {
            name: 'Filters',
            url: '/catalog/filter',
            icon: 'fa fa-filter'
          },
          {
            name: "Attributes",
            url: '/catalog/attribute',
            icon: "fa fa-tags",
            children: [
              {
                name: 'Attribute',
                url: '/catalog/attribute',
                icon: 'fa fa-tag',
              },
              {
                name: 'Attribute Groups',
                url: '/catalog/attribute_group',
                icon: 'fa fa-tag',
              },
            ]
          },
          {
            name: 'Options',
            url: '/catalog/option',
            icon: 'cui-options'
          },
          {
            name: 'Manufacturers',
            url: '/catalog/manufacturer',
            icon: 'fa fa-industry'
          },
          {
            name: 'Reviews',
            url: '/catalog/reviews',
            icon: 'fa fa-comments'
          },
          {
            name: 'Reviews',
            url: '/catalog/review',
            icon: 'fa fa-comments'
          },
          {
            name: 'Information',
            url: '/catalog/information',
            icon: 'fa fa-info-circle'
          },
          {
            name: 'Messages',
            url: '/catalog/messages',
            icon: 'cui-chat'
          },
        ]
      },
      {
        name: "Business",
        url: '/business',
        icon: "fa fa-briefcase",
        children: [
          {
            name: 'Business',
            url: '/business/business',
            icon: 'fa fa-briefcase'
          },
          {
            name: 'Plan',
            url: '/business/plan',
            icon: 'fa fa-check-square-o'
          },
          {
            name: 'Transaction',
            url: '/business/transaction',
            icon: 'fa fa-exchange'
          }
        ]
      },
      {
        name: "Promotions",
        url: '/promotion',
        icon: "fa fa-bullhorn",
        children: [
          {
            name: 'Promotion',
            url: '/promotion/promotion',
            icon: 'fa fa-bullhorn'
          },
          {
            name: 'Discount',
            url: '/promotion/discount',
            icon: 'fa fa-percent'
          },
          {
            name: 'Promo Code',
            url: '/promotion/promo_code',
            icon: 'fa fa-code'
          }
        ]
      },
      {
        name: "Online Store",
        url: '/store',
        icon: "fas fa-shopping-bag",
        children: [
          {
            name: 'Store',
            url: '/store/store',
            icon: 'fas fa-shopping-bag'
          },
          {
            name: 'Franchise',
            url: '/store/franchise',
            icon: 'fa fa-industry'
          },
          {
            name: 'Digital',
            url: '/business/digital_card',
            icon: 'fas fa-shopping-bag'
          },
          {
            name: 'Link',
            url: '/store/link',
            icon: 'fa fa-external-link'
          }
        ]
      },
      {
        name: "Inventory",
        url: '/inventory',
        icon: "fa fa-warehouse",
        children: [
          {
            name: 'Purchase',
            url: '/inventory/purchase',
            icon: 'icon-basket'
          },
          {
            name: 'Supplier',
            url: '/inventory/supplier',
            icon: 'fa fa-user'
          },
          {
            name: 'Warehouse',
            url: '/inventory/warehouse',
            icon: 'fa fa-warehouse'
          }
        ]
      },
      {
        name: "Delivery",
        url: '/delivery',
        icon: "fas fa-truck",
        children: [
          {
            name: 'Routes',
            url: '/delivery/routes',
            icon: 'fa fa-map-marker'
          }
        ]
      },
      {
        name: "Media",
        url: '/mediaFiles',
        icon: "fa fa-file-o",
        children: [
          {
            name: 'Images',
            url: '/tool/upload',
            icon: 'fa fa-file-image-o'
          },
          {
            name: 'Videos',
            url: '/tool/video',
            icon: 'fa fa-play'
          },
          {
            name: 'Icons',
            url: '/tool/icon',
            icon: 'fa fa-file-image-o'
          },
          {
            name: 'Downloads',
            url: '/mediaFiles/downloads',
            icon: 'fa fa-download'
          },
          {
            name: 'slide',
            url: '/mediaFiles/slide',
            icon: 'far fa-images'
          }
        ]
      },
      {
        name: "Sales",
        url: '/sale',
        icon: "icon-basket",
        children: [
          {
            name: 'Orders',
            url: '/sale/order',
            icon: 'icon-basket'
          },
          {
            name: 'Subscriptions',
            url: '/plan/subscription',
            icon: 'icon-basket'
          },
          {
            name: 'Recurring Profiles',
            url: '/sales/recurring_profiles',
            icon: 'icon-basket'
          }
        ]
      },
      {
        name: "Users",
        url: '/system',
        icon: "fas fa-user-alt",
        children: [
          {
            name: 'Users',
            url: '/system/user',
            icon: 'fas fa-user-alt'
          },
          {
            name: 'User Groups',
            url: '/system/user_group',
            icon: 'fas fa-user-friends'
          },
          {
            name: 'Time Tracking',
            url: '/system/time_tracking',
            icon: 'fas fa-clock'
          },
          {
            name: 'Equipment',
            url: '/system/equipment',
            icon: 'icon-settings'
          },
        ]
      },
      {
        name: "Customers",
        url: '/user',
        icon: "fas fa-user-alt",
        children: [
          {
            name: 'Customer',
            url: '/user/customer',
            icon: 'fas fa-user-alt'
          },
          {
            name: 'Customer Group',
            url: '/user/customer_group',
            icon: 'fas fa-user-friends'
          }
        ]
      },
      {
        name: "Communications",
        url: '/system',
        icon: 'icon-layers',
        children: [
          {
            name: 'Chat',
            url: '/user/chat',
            icon: 'fa fa-commenting-o',
          },
          {
            name: "Calls",
            url: '/system/notification/settings',
            icon: "fas fa-phone",
            children: [
              {
                name: 'Call',
                url: '/call/tickets',
                icon: 'fas fa-phone-volume'
              },
              {
                name: 'Store History',
                url: '/call/calls',
                icon: 'fas fa-phone'
              },
              {
                name: 'Compose',
                url: '/call/send',
                icon: 'fas fa-envelope'
              },
              {
                name: 'History',
                url: '/call/history',
                icon: 'fas fa-phone'
              },
              {
                name: 'Campaign',
                url: '/call/call_group',
                icon: 'fas fa-poll'
              }
            ]
          },
          {
            name: "SMS",
            url: '/system/notification/settings',
            icon: "fas fa-sms",
            children: [
              // {
              //   name: 'Compose',
              //   url: '/sms/send',
              //   icon: 'fas fa-envelope',
              // },
              {
                name: 'History',
                url: '/sms/history',
                icon: 'fas fa-history',
              },
              {
                name: 'Campaign',
                url: '/sms/campaign',
                icon: 'fas fa-poll',
              },
              {
                name: 'Domain',
                url: '/sms/domain',
                icon: 'fas fa-poll',
              },
              {
                name: 'Phone',
                url: '/sms/phone',
                icon: 'fas fa-poll',
              },
              {
                name: 'Automated SMS',
                url: '/call/sms',
                icon: 'fas fa-history',
              },
            ]
          }
        ]
      },
      {
        name: "System",
        url: '/system',
        icon: 'icon-settings',
        children: [
          {
            name: "Notifications",
            url: '/system/notification/settings',
            icon: "far fa-bell",
            children: [
              {
                name: 'Notification Types',
                url: '/system/notification/type',
                icon: 'fa fa-file-o',
              },
              {
                name: 'Notification Sound',
                url: '/system/notification/sound',
                icon: 'fas fa-volume-up	',
              },
              {
                name: 'Notific... Settings',
                url: '/system/notification/settings',
                icon: 'icon-settings',
              },
            ]
          },
          {
            name: 'Languages',
            url: '/localisation/language',
            icon: 'fa fa-language',
          },
          {
            name: 'Currency',
            url: '/localisation/currency',
            icon: 'fa fa-money',
          }
        ]
      }
    ]

    return {
      items: items
    }

  }
}

export default Nav