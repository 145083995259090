import React, { useState, useEffect, useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle } from '@coreui/coreui/dist/js/coreui-utilities';
import WebPush from '../../WebPush';
import config from '../../config/app';
import TrafficChart from '../Charts/TrafficChart';
import CallChart from '../Charts/CallChart';
import ProductChart from '../Charts/ProductChart';
import OrderChart from '../Charts/OrderChart';
import UserChart from '../Charts/UserChart';
// import OrderMap from '../Charts/OrderMap';
import SmsChart from '../Charts/SmsChart';
import StoreSmsChart from '../Charts/StoreSmsChart';
import CartChart from '../Charts/CartChart';
import {payloadFromSubscription} from '../../Utility';
import ReactSelect from 'react-select';
import {connect} from 'react-redux';
import {
  customerActions,
  businessActions
} from "../../projectNulla/redux-modules/actions";
import { DatePicker } from '../../projectNulla/catalogs/Globals';
import StaffChart from '../Charts/StaffChart';
const applicationServerPublicKey = "BLHxWiNVmr7ROB8O3KpPRJFAMhMypwe4X9TdWMmhsPSzHszo32PDkndpvWx3H0OY2HwFCQRU98JBpZ_AEsVxWG4"

const brandPrimary = getStyle('--primary')
const brandInfo = getStyle('--info')

function Dashboard(props) {
    const { account, permissions, customerStatistics, businessSelect, businessCount } = props;
    const [visibleCharts, setVisibleCharts] = useState([]);
    const chartRefs = useRef([]);
    const resizeObserverRefs = useRef([]);
    const [subscription, setSubscription] = useState({
        endpoint: `${config.API}subscription/save-subscription`
    })
    const selectRef = useRef([]);
    const [selectedBusiness, setSelectedBusines] = useState([])
    const [closeOnSelectBusiness, setCloseOnSelectBusiness] = useState(false)
    const [page, setPage] = useState(1)
    const [card1IsOpen, setCard1IsOpen] = useState(false)
    const [card2IsOpen, setCard2IsOpen] = useState(false)
    const [card3IsOpen, setCard3IsOpen] = useState(false)
    const [card4IsOpen, setCard4IsOpen] = useState(false)

    const subscriveUserEnabled = true

    const onSubscriptionFailed = (error) => {
        console.log("onSubscriptionFailed:", error)
    }


    const getClientLanguage = () => {
        let Locale = navigator.language || navigator.userLanguage;
        if (Locale.includes('-')) Locale = Locale.split('-')[0];
        return Locale.toLowerCase();
    };

    const genericFormattedDate = (DateToFormat) => {
        const UserLanguage = getClientLanguage();
        const Config = { year: 'numeric', month: 'long', hour: '2-digit', minute:'2-digit', day: '2-digit' };
        return new Date(DateToFormat).toLocaleString(UserLanguage, Config);
    };

    const cardChartData1 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: brandPrimary,
                borderColor: 'rgba(255,255,255,.55)',
                data: customerStatistics.data.map(d => d.count),
            },
        ],
    }

    const cardChartOpts1 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
            {
                gridLines: {
                    color: 'transparent',
                    zeroLineColor: 'transparent',
                },
                ticks: {
                    fontSize: 2,
                    fontColor: 'transparent',
                },
        
            }],
            yAxes: [
                {
                    display: false,
                    ticks: {
                        display: false,
                        min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
                        max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
                    },
                }
            ],
        },
        elements: {
            line: {
                borderWidth: 1,
            },
            point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
            },
        }
    }

    // Card Chart 2
    const cardChartData2 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: brandInfo,
                borderColor: 'rgba(255,255,255,.55)',
                data: customerStatistics.data.map(d => d.ordercount)
            },
        ],
    };

    const cardChartOpts2 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
            {
                gridLines: {
                    color: 'transparent',
                    zeroLineColor: 'transparent',
                },
                ticks: {
                    fontSize: 2,
                    fontColor: 'transparent',
                },

            }],
            yAxes: [
            {
                display: false,
                ticks: {
                    display: false,
                    min: Math.min.apply(Math, cardChartData2.datasets[0].data) - 5,
                    max: Math.max.apply(Math, cardChartData2.datasets[0].data) + 5,
                },
            }],
        },
        elements: {
            line: {
                tension: 0.00001,
                borderWidth: 1,
            },
            point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
            },
        },
    };

    // Card Chart 3
    const cardChartData3 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(255,255,255,.2)',
                borderColor: 'rgba(255,255,255,.55)',
                data: customerStatistics.data.filter(d => !!(parseInt(d.donecount) - parseInt(d.uniquecount))).map(d => parseInt(d.donecount) - parseInt(d.uniquecount))
            },
        ],
    };
    
    const cardChartOpts3 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    display: false,
                }
            ],
            yAxes: [
                {
                    display: false,
                }
            ],
        },
        elements: {
            line: {
                borderWidth: 2,
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
            },
        },
    };

    // Card Chart 4
    const cardChartData4 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(255,255,255,.3)',
                borderColor: 'transparent',
                data: customerStatistics.data.filter(d => parseInt(d.donecount)).map(d => d.donecount),
            },
        ],
    };

    const cardChartOpts4 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
            {
                display: false,
                barPercentage: 0.6,
            }],
            yAxes: [
            {
                display: false,
            }],
        },
    };


    //Random Numbers
    function random(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    var elements = 27;
    var data1 = [];
    var data2 = [];
    var data3 = [];

    for (var i = 0; i <= elements; i++) {
        data1.push(random(50, 200));
        data2.push(random(80, 100));
        data3.push(65);
    }

    const [date, setDate] = useState({ startDate: '', endDate: '' })
    const [radioSelected, setRadioSelected] = useState('week');

    function handleChangeDate (event, key) {
        setDate({
            ...date,
            [key]: event.target.value
        })
    }

    useEffect(() => {
        props.getCustomerStatistics(account.token, {
            date: radioSelected,
            startDate: date.startDate,
            endDate: date.endDate,
            businessIds: selectedBusiness.map(({value}) => value) 
        })
    }, [radioSelected, date, selectedBusiness, selectedBusiness])

    const onUpdateSubscriptionOnServer = (subscription) => {
        const payload = payloadFromSubscription(subscription)
        if (localStorage.getItem('account')) {
            const SERVER_URL = `${config.API}subscription/save-subscription`;
            fetch(SERVER_URL, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem('account')).token
                },
                body: JSON.stringify(payload)
            });
        }
        setSubscription(subscription)
    }

    function onRadioBtnClick(value) {
        setRadioSelected(value)
    }


    function openMenu(index) {
        if (index == 0) {
            if (!businessSelect.length) {
                props.fetchBusinessSeeAll(account.token, 1, 10);  
            }
        }
        selectRef.current[index].focus()
    }

    const handleInputChangeBusiness = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        props.fetchBusinessSeeAll(account.token, 1, 10, { name: inputValue })
    };

    const selectBusiness = (selectedOption) => {
        let selectedOptions = []
        if (selectedOption.length) {
            selectedOption.forEach(elem => {
                if (elem.value === 'all') {
                    let businessPage = page + 1
                    setPage(businessPage)
                    setCloseOnSelectBusiness(false)
                    props.fetchBusinessSeeAll(account.token, businessPage, 10)
                }  else {
                    selectedOptions.push(elem)
                }
            })
        }
        setSelectedBusines(selectedOptions)
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const chartName = entry.target.dataset.chart;
                        if (!visibleCharts.includes(chartName)) {
                            setVisibleCharts((prev) => [...prev, chartName]);
                        }
                    }
                });
            },
            { threshold: 0.4 }
        );

        const resizeObserver = new ResizeObserver(() => {
            chartRefs.current.forEach((ref) => {
                if (ref) {
                    intersectionObserver.observe(ref);
                }
            });
        });

        chartRefs.current.forEach((ref, index) => {
            if (ref) {
                intersectionObserver.observe(ref);
                resizeObserver.observe(ref);
                resizeObserverRefs.current[index] = resizeObserver;
            }
        });

        return () => {
            chartRefs.current.forEach((ref) => ref && intersectionObserver.unobserve(ref));
            resizeObserverRefs.current.forEach((observer) => observer && observer.disconnect());
        };
    }, [visibleCharts]);

    console.log(visibleCharts, 'dd')
    return (
        <div className="animated fadeIn">       
            <WebPush
                subscriveUserEnabled={subscriveUserEnabled}
                applicationServerPublicKey={applicationServerPublicKey}
                onSubscriptionFailed={onSubscriptionFailed}
                onUpdateSubscriptionOnServer={onUpdateSubscriptionOnServer}
            />
            {
                !!permissions.find(({value}) => value == 'customer/statistics') &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="3">
                                        <DatePicker
                                            handleChange={handleChangeDate}
                                            value={{
                                                startDate: date.startDate,
                                                endDate: date.endDate 
                                            }}
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <div style={{width: "200px"}}>
                                            <ReactSelect
                                                ref={el => selectRef.current[0] = el}
                                                onFocus={() => openMenu(0)}
                                                closeOnSelect = {closeOnSelectBusiness}
                                                name="form-field-name"
                                                value={selectedBusiness}
                                                onInputChange={handleInputChangeBusiness}
                                                multi = {true}
                                                onChange={selectBusiness}
                                                options={
                                                    businessCount > businessSelect.length 
                                                    ? [...businessSelect, { value: 'all', label: 'See More'}]
                                                    : [...businessSelect]
                                                }
                                                placeholder={<div>Business</div>}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            <div style={{ minHeight: '142px', backgroundColor: '#e4e5e6' }}>
                {
                    !!permissions.find(({value}) => value == 'customer/statistics') &&
                    <div ref={(el) => chartRefs.current[2] = el} data-chart="CustomerChart">
                        {
                            visibleCharts.includes("CustomerChart") &&
                            <Row>
                                <Col xs="12" sm="6" lg="3">
                                    <Card className="text-white bg-primary">
                                        <CardBody className="pb-0">
                                            <ButtonGroup className="float-right">
                                                <ButtonDropdown 
                                                    id='card1' 
                                                    isOpen={card1IsOpen} 
                                                    toggle={() => setCard1IsOpen(!card1IsOpen)}
                                                >
                                                    <DropdownToggle caret className="p-0" color="transparent">
                                                    <i className="icon-settings"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                                            <div className="text-value">{customerStatistics.data.map(item => item.count).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                            <div>Customers</div>
                                        </CardBody>
                                        <div className="chart-wrapper mx-3" style={{ height: '70px' }}>
                                            <Line data={cardChartData1} options={cardChartOpts1} height={70} />
                                        </div>
                                    </Card>
                                </Col>
                                
                                <Col xs="12" sm="6" lg="3">
                                    <Card className="text-white bg-info">
                                        <CardBody className="pb-0">
                                            <ButtonGroup className="float-right">
                                                <ButtonDropdown 
                                                    id='card2' 
                                                    isOpen={card2IsOpen} 
                                                    toggle={() => setCard2IsOpen(!card2IsOpen)}
                                                >
                                                    <DropdownToggle caret className="p-0" color="transparent">
                                                    <i className="icon-settings"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                    <DropdownItem onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                                            <div className="text-value">{customerStatistics.data.filter(item => parseInt(item.ordercount)).map(item => item.ordercount).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                            <div>Customers order</div>
                                        </CardBody>
                                        <div className="chart-wrapper mx-3" style={{ height: '70px' }}>
                                            <Line data={cardChartData2} options={cardChartOpts2} height={70} />
                                        </div>
                                    </Card>
                                </Col>
                        
                                <Col xs="12" sm="6" lg="3">
                                    <Card className="text-white bg-warning">
                                        <CardBody className="pb-0">
                                            <ButtonGroup className="float-right">
                                                <ButtonDropdown 
                                                    id='card3' 
                                                    isOpen={card3IsOpen} 
                                                    toggle={() => setCard3IsOpen(!card3IsOpen)}
                                                >
                                                    <DropdownToggle caret className="p-0" color="transparent">
                                                    <i className="icon-settings"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                    <DropdownItem onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</DropdownItem>
                                                        <DropdownItem onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                                            <div className="text-value">{customerStatistics.data.filter(item => (parseInt(item.donecount) - parseInt(item.uniquecount))).map(item => parseInt(item.donecount) - parseInt(item.uniquecount)).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                            <div>Returning customers</div>
                                        </CardBody>
                                        <div className="chart-wrapper" style={{ height: '70px' }}>
                                            <Line data={cardChartData3} options={cardChartOpts3} height={70} />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs="12" sm="6" lg="3">
                                    <Card className="text-white bg-danger">
                                        <CardBody className="pb-0">
                                            <ButtonGroup className="float-right">
                                                <ButtonDropdown 
                                                    id='card4' 
                                                    isOpen={card4IsOpen} 
                                                    toggle={() => setCard4IsOpen(!card4IsOpen)}
                                                >
                                                    <DropdownToggle caret className="p-0" color="transparent">
                                                    <i className="icon-settings"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                    <DropdownItem>Action</DropdownItem>
                                                    <DropdownItem>Another action</DropdownItem>
                                                    <DropdownItem>Something else here</DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                                            <div className="text-value">{customerStatistics.data.filter(item => parseInt(item.donecount)).map(item => item.donecount).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                            <div>Customers done orders</div>
                                        </CardBody>
                                        <div className="chart-wrapper mx-3" style={{ height: '70px' }}>
                                            <Bar data={cardChartData4} options={cardChartOpts4} height={70} />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </div>
                }
            </div>
            {
                !!permissions.find(({value}) => value == 'traffic/statistics') &&
                <Row>
                    <Col>
                        <div ref={(el) => chartRefs.current[3] = el} data-chart="TrafficChart">
                            {
                                visibleCharts.includes("TrafficChart") &&
                                <TrafficChart/>
                            }
                        </div>
                    </Col>
                </Row>
            }
            <div style={{ minHeight: '290px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    <Col>
                        {
                            !!permissions.find(({value}) => value == 'call/statistics') &&
                            <div ref={(el) => chartRefs.current[4] = el} data-chart="CallChart">
                                {
                                    visibleCharts.includes("CallChart") &&
                                    <CallChart/>
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <div style={{ minHeight: '290px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    <Col>
                        {
                            !!permissions.find(({value}) => value == 'call/statistics') &&
                            <div ref={(el) => chartRefs.current[5] = el} data-chart="UserChart">
                                {
                                    visibleCharts.includes("UserChart") &&
                                    <UserChart
                                        data={props.callStatistics.agentsStatistics.map((elem) => ({ 
                                            ...elem, 
                                            username: elem.user ? elem.user.username : 'other' 
                                        }))}
                                        chartConfigs = {[
                                            { key: 'inbound', title: 'Inbound' },
                                            { key: 'outbound', title: 'Outbound' },
                                            { key: 'answerd', title: 'Answered' },
                                            { key: 'missed', title: 'Missed' },
                                            { key: 'busy', title: 'Busy' }
                                        ]}
                                    />
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <div style={{ minHeight: '450px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    <Col>
                        {
                            !!permissions.find(({value}) => value == 'sale/order/statistics') &&
                            <div ref={(el) => chartRefs.current[6] = el} data-chart="OrderChart">
                                {
                                    visibleCharts.includes("OrderChart") &&
                                    <OrderChart/>
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <div style={{ minHeight: '450px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    <Col>
                        {
                            !!permissions.find(({value}) => value == 'sale/order/statistics') &&
                            <div ref={(el) => chartRefs.current[7] = el} data-chart="ProductChart">
                                {
                                    visibleCharts.includes("ProductChart") &&
                                    <ProductChart businessStatistics={!!permissions.find(({value}) => value == 'business/statistics')}/>
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <div style={{ minHeight: '450px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    {
                        !!permissions.find(({value}) => value == 'sale/order/statistics') &&
                        <div style={{ width: '100%' }} ref={(el) => chartRefs.current[8] = el} data-chart="StaffChart">
                            {
                                visibleCharts.includes("StaffChart") &&
                                <Col>
                                    <StaffChart data={props.orderStatistics}/>
                                </Col>
                            }
                        </div>
                    }
                </Row>
            </div>
            <Row>
                <Col>
                    {/* {
                        !!permissions.find(({value}) => value == 'sale/order/statistics') &&
                        <OrderMap/>
                    } */}
                </Col>
            </Row>
            <div style={{ minHeight: '450px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    <Col>
                        {
                            !!permissions.find(({value}) => value == 'sms/sms/statistics') &&
                            <div ref={(el) => chartRefs.current[9] = el} data-chart="SmsChart">
                                {
                                    visibleCharts.includes("SmsChart") &&
                                    <SmsChart/>
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <div style={{ minHeight: '450px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    <Col>
                        {
                            !!permissions.find(({value}) => value == 'call/sms/statistics') &&
                            <div ref={(el) => chartRefs.current[10] = el} data-chart="StoreSmsChart">
                                {
                                    visibleCharts.includes("StoreSmsChart") &&
                                    <StoreSmsChart/>
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <div style={{ minHeight: '450px', backgroundColor: '#e4e5e6' }}>
                <Row>
                    <Col>
                        {
                            !!permissions.find(({value}) => value == 'cart/statistics') &&
                            <div ref={(el) => chartRefs.current[11] = el} data-chart="CartChart">
                                {
                                    visibleCharts.includes("CartChart") && 
                                    <CartChart/>
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
        permissions: state.account.userGroup.permissions,
        customerStatistics: state.customers.statistics,
        businessCount: state.business.count,
        businessSelect: state.business.businessSelect,
        orderStatistics: state.newOrders.statistics,
        callStatistics: state.call.statistics
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerStatistics: (jwt, query) => dispatch(customerActions.getStatistics(jwt, query)), 
        fetchBusinessSeeAll: (jwt, page, count, query, search) => dispatch(businessActions.seeAll(jwt, page, count, query, search)) 
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
