import  { CAMPAIGN_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

class CampaignActions {
    constructor(_props) {
        this.api = config['SMS_API']
        this.rest  = 'campaign/'
        this.arcive = 'b/'
    }

    pagination = (jwt, params, page, count, query) => {
        return async dispatch => {
            try {

                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + this.rest + `${params}` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                if (data.data.length) {
                    data.data = data.data.map(item => ({ businesName: item.business.name, userName: item.user?.username, ...item }))
                }

                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_PAG_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_PAG_R,
                    payload: e
                })
            }
        }
    }

    paginationBusiness = (jwt, page, count, query, search) => {
        return async dispatch => {
            try {

                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_BUSINESS_PAG_P,
                })

                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + this.rest + `business/` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_BUSINESS_PAG_F,
                    payload: data,
                    search: search
                })

            } catch (e) {
                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_BUSINESS_PAG_R,
                    payload: e
                })
            }
        }
    }

    destroy = (jwt, id, Type) => {
        return async dispatch => {
            try {
                await axios.delete(
                    this.api + this.rest + `/${id}`,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
                dispatch({
                    type: CAMPAIGN_TYPES[Type + '_DELETE_F'],
                    payload: id
                })
            } catch (e) {
                dispatch({
                    type: CAMPAIGN_TYPES[Type + '_DELETE_R'],
                    payload: e
                })
            }
        }
    }

    getOne = (jwt, id, Type) => {
        return async dispatch => {
            try {

                dispatch({
                    type: CAMPAIGN_TYPES[Type + '_GET_ONE_P']
                })

                const response = await axios.get(
                    this.api + this.rest + `${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: CAMPAIGN_TYPES[Type + '_GET_ONE_F'],
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: CAMPAIGN_TYPES[Type + '_GET_ONE_R'],
                    payload: e
                })

            }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
    */
    bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                this.api + this.rest + this.arcive + params,
                ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_BULK_ARCIVE_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_BULK_ARCIVE_R,
                    payload: e
                })

            }
        }
    }

    /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
    */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                    this.api + this.rest + 'b/d',
                    ids,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_BULK_DESTROY_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: CAMPAIGN_TYPES.CAMPAIGN_BULK_DESTROY_R,
                    payload: e
                })

            }
        }
    }

    newCampaign = () => {
        return dispatch => {
            return dispatch({
                type: CAMPAIGN_TYPES.CAMPAIGN_NEW
            })
        }
    };

}




export default new CampaignActions()